import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import Header from './header.vue';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import('./index.vue');
routes.push({
    name: 'premiums-simulation',
    path: 'premiums-simulation',
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_RESTRICTED_PARTICIPANT_AUTHORIZE_RESOURCE,
        headerComponents: {
            extension: {
                component: Header,
                height: 135,
            },
        },
        menuTitle: 'premiumsSimulation',
        icon: 'trending_up',
        title: 'premiumsSimulation',
    },
});
export default routes;
