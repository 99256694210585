import MicroServices from '@/configs/MicroServices.types';
import proxy from '@/store/proxy';
import { replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import messages from './locales';
export default {
    namespace: 'planMember',
    uri: '/api/clients/:{clientGuid}/groups/:{groupGuid}/planmembers/:{participantGuid}/',
    get(clientGuid, groupGuid, participantGuid, query) {
        const methodName = 'get';
        const service = MicroServices.BFF;
        const params = {
            clientGuid,
            groupGuid,
            participantGuid,
        };
        const url = this.uri;
        const replacedUrl = replaceStringRoutingData(`${url}`, params);
        const requestData = {
            namespace: this.namespace,
            url,
            params,
            query,
            methodName,
            service,
            messages,
        };
        return proxy.get(replacedUrl, requestData);
    },
};
