import Status from '@/components/ui/status.vue';
import I18n from '@/plugins/vue-i18n';
import { getLocalizedValueByLanguage } from '@/utils/getLocalizedItem';
export default function getComponentParticipantStatus(status, language, large = false) {
    if (!status) {
        return '';
    }
    const description = getLocalizedValueByLanguage(status.description, language);
    const isFutureStatusData = {
        state: description,
        date: status.effectiveDate,
    };
    return {
        component: Status,
        props: {
            status: status.status,
            text: status.isFutureStatus ? I18n.t('common.stateFutureWithDate', isFutureStatusData) : description,
            large,
            icon: status.isFutureStatus ? 'trip_origin' : 'circle',
        },
    };
}
