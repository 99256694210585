import SETTINGS from '../constants/settings';
import { i18n } from '../utils/converters/input-types/i18n';
import { formatDate } from '../utils/date';
import { getEnumValue, getEnumValues, toValues } from './_utils';
export var statusKeys;
(function (statusKeys) {
    statusKeys[statusKeys["ACTIVE"] = 0] = "ACTIVE";
    statusKeys[statusKeys["TERMINATED"] = 1] = "TERMINATED";
})(statusKeys || (statusKeys = {}));
;
const CONSTANT = {
    ACTIVE: statusKeys.ACTIVE,
    TERMINATED: statusKeys.TERMINATED,
};
const values = toValues(CONSTANT);
const FR = {
    [CONSTANT.ACTIVE]: 'Actif',
    [CONSTANT.TERMINATED]: 'Terminé',
};
const EN = {
    [CONSTANT.ACTIVE]: 'Active',
    [CONSTANT.TERMINATED]: 'Terminated',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function getStatusCssClassColor(code) {
    if (code === CONSTANT.ACTIVE) {
        return 'green';
    }
    if (code === CONSTANT.TERMINATED) {
        return 'red';
    }
    return '';
}
export function getStatusCssClassColorText(code) {
    const css = getStatusCssClassColor(code);
    if (css !== '') {
        return `${css}--text`;
    }
    return '';
}
function getStatusIcon(statusCode) {
    const statusIcon = {
        cssClassIcon: getStatusCssClassColorText(statusCode),
        toolTipText: {},
    };
    SETTINGS.LANGUAGES.forEach((language) => {
        statusIcon.toolTipText[language] = getValuePerCode(statusCode, language).Value;
    });
    return statusIcon;
}
// Used on the employer portal where we display a different icon if the status changes in the future
function getStatusIconName(nextStatusCode) {
    if (nextStatusCode === null) {
        return 'circle';
    }
    return 'trip_origin';
}
// Used on the employer portal where we display the status if there are changes in the future
function getFutureStatus(text, nextStatusEffectiveDate, nextStatus) {
    const dictionary = {
        en: {
            [CONSTANT.ACTIVE]: 'Will be active on the',
            [CONSTANT.TERMINATED]: 'Will be terminated on the',
        },
        fr: {
            [CONSTANT.ACTIVE]: 'Sera active le',
            [CONSTANT.TERMINATED]: 'Se terminera le',
        },
    };
    const i18nText = i18n(text);
    if (nextStatusEffectiveDate) {
        Object.keys(i18nText).forEach((key) => {
            i18nText[key] = `${dictionary[key][nextStatus]} ${formatDate(nextStatusEffectiveDate)}`;
        });
    }
    else {
        Object.keys(i18nText).forEach((key) => {
            i18nText[key] = '';
        });
    }
    return i18nText;
}
const STATUS = {
    CONSTANT,
    getValues,
    getValuePerCode,
    getCssClassColor: getStatusCssClassColor,
    getStatusCssClassColorText,
    getStatusIcon,
    getStatusIconName,
    getFutureStatus,
};
export default STATUS;
