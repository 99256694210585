import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import HeaderContent from './header-content.vue';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "documents" */ './index.vue');
routes.push({
    name: 'documents',
    path: 'documents',
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_DOCUMENTS_VAULT_RESOURCE,
        title: 'Documents',
        menuTitle: 'documents',
        icon: 's-icon-file',
        headerComponents: {
            extension: {
                component: HeaderContent,
                height: 75,
            },
        },
    },
});
export default routes;
