import { get } from 'lodash';
import { removeSpecialCharacters } from './string';
import { isObject } from './types';
export function sortDataByParams(data, sortParams = [], sortOrder = []) {
    if (!data && !sortParams?.length) {
        return [];
    }
    return data.sort((a, b) => {
        if (isObject(a) && isObject(b)) {
            for (let i = 0; i < sortParams.length; i += 1) {
                let valueA = get(a, sortParams[i]);
                let valueB = get(b, sortParams[i]);
                // Convert to numbers if necessary
                valueA = isNaN(valueA) ? removeSpecialCharacters(valueA).toLowerCase() : Number(valueA);
                valueB = isNaN(valueB) ? removeSpecialCharacters(valueB).toLowerCase() : Number(valueB);
                if (sortOrder?.[i] && sortOrder[i] !== 'asc' && sortOrder[i] !== 'desc') {
                    console.warn('Sorting needs to be either asc or desc');
                    sortOrder[i] = 'asc';
                }
                const order = sortOrder[i] === 'desc' ? -1 : 1;
                // Compare the values
                if (valueA < valueB)
                    return -1 * order;
                if (valueA > valueB)
                    return 1 * order;
            }
        }
        else {
            const order = sortOrder[0] === 'desc' ? -1 : 1;
            return a.localeCompare(b) * order;
        }
        // If all keys are the same, return 0
        return 0;
    });
}
