import BCP_LANGUAGE_CODES from 'segic_shared_front_end_utils/src/constants/bcp-language-codes';
export function getLocalizedItemByLanguage(cultureList, language) {
    if (!cultureList?.length) {
        return null;
    }
    const item = cultureList.find((cultureItem) => cultureItem.cultureName === BCP_LANGUAGE_CODES[language]);
    return item || null;
}
export function getLocalizedValueByLanguage(cultureList, language, key = 'text') {
    return getLocalizedItemByLanguage(cultureList, language)?.[key] || null;
}
