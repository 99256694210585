import { clone, orderBy } from 'lodash';
import STATUS from 'segic_shared_front_end_utils/src/enums/status';
import { formatDate, today } from 'segic_shared_front_end_utils/src/utils/date';
import { getActiveItem, getFutureItem } from 'segic_shared_front_end_utils/src/utils/history';
import { getI18n, getI18nFromCulture } from 'segic_shared_front_end_utils/src/utils/i18n';
export function formatEffectiveDates(CategoryDetails) {
    return orderBy(CategoryDetails.map((categoryDetail) => ({
        ...categoryDetail,
        EffectiveDate: formatDate(categoryDetail.EffectiveDate),
    })), ['EffectiveDate'], 'desc');
}
export default {
    input({ CategoryDetails, Code, HasChildren, Id, Name, }) {
        const categoryDetailsWithFormattedDates = formatEffectiveDates(CategoryDetails);
        const activeCategoryDetails = getActiveItem(categoryDetailsWithFormattedDates, 'EffectiveDate', today(), false, true);
        const futureCategoryDetails = getFutureItem(categoryDetailsWithFormattedDates, 'EffectiveDate', today());
        const name = getI18n(getI18nFromCulture(Name));
        return {
            Id,
            CategoryId: Id,
            Code,
            EffectiveDate: formatDate(activeCategoryDetails?.EffectiveDate),
            HasChildren,
            Name: getI18n(getI18nFromCulture(Name)),
            DisplayCodeName: {
                fr: `${Code} - ${name.fr}`,
                en: `${Code} - ${name.en}`,
            },
            NextStatus: futureCategoryDetails ? futureCategoryDetails?.Status : null,
            NextStatusEffectiveDate: futureCategoryDetails ? futureCategoryDetails?.EffectiveDate : null,
            Status: activeCategoryDetails?.Status,
            StatusIconColor: STATUS.getCssClassColor(futureCategoryDetails ? futureCategoryDetails?.Status : activeCategoryDetails?.Status),
            StatusIcon: STATUS.getStatusIconName(futureCategoryDetails ? futureCategoryDetails?.Status : null),
            Subtitle: STATUS.getFutureStatus(getI18nFromCulture(clone(Name)), futureCategoryDetails?.EffectiveDate, futureCategoryDetails ? futureCategoryDetails?.Status : null),
            Text: name,
            CategoryDetails: categoryDetailsWithFormattedDates,
        };
    },
};
