import STATUS_COLORS from '../constants/status-color';
import { getEnumValue, getEnumValues, toValuesEnum } from './_utils';
export var INSURANCE_STATUS_CONSTANT;
(function (INSURANCE_STATUS_CONSTANT) {
    INSURANCE_STATUS_CONSTANT[INSURANCE_STATUS_CONSTANT["ACTIVE"] = 0] = "ACTIVE";
    INSURANCE_STATUS_CONSTANT[INSURANCE_STATUS_CONSTANT["EXEMPTED"] = 1] = "EXEMPTED";
    INSURANCE_STATUS_CONSTANT[INSURANCE_STATUS_CONSTANT["TERMINATED"] = 2] = "TERMINATED";
    INSURANCE_STATUS_CONSTANT[INSURANCE_STATUS_CONSTANT["EXONERATED"] = 3] = "EXONERATED";
})(INSURANCE_STATUS_CONSTANT || (INSURANCE_STATUS_CONSTANT = {}));
const values = toValuesEnum(INSURANCE_STATUS_CONSTANT);
const FR = {
    [INSURANCE_STATUS_CONSTANT.ACTIVE]: 'Actif',
    [INSURANCE_STATUS_CONSTANT.EXEMPTED]: 'Exempté',
    [INSURANCE_STATUS_CONSTANT.TERMINATED]: 'Terminé',
    [INSURANCE_STATUS_CONSTANT.EXONERATED]: 'Exonéré',
};
const EN = {
    [INSURANCE_STATUS_CONSTANT.ACTIVE]: 'Active',
    [INSURANCE_STATUS_CONSTANT.EXEMPTED]: 'Exempted',
    [INSURANCE_STATUS_CONSTANT.TERMINATED]: 'Terminated',
    [INSURANCE_STATUS_CONSTANT.EXONERATED]: 'Exonerated',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function getInsuranceStatusCssClassColor(statusCode) {
    let color = '';
    switch (statusCode) {
        case INSURANCE_STATUS_CONSTANT.ACTIVE:
            color = STATUS_COLORS.GREEN;
            break;
        case INSURANCE_STATUS_CONSTANT.EXEMPTED:
            color = STATUS_COLORS.ORANGE;
            break;
        case INSURANCE_STATUS_CONSTANT.TERMINATED:
            color = STATUS_COLORS.RED;
            break;
        case INSURANCE_STATUS_CONSTANT.EXONERATED:
            color = STATUS_COLORS.ORANGE;
            break;
        default:
            color = '';
    }
    return color;
}
export function isActive(status) {
    return status === INSURANCE_STATUS_CONSTANT.ACTIVE;
}
export function isTerminated(status) {
    return status === INSURANCE_STATUS_CONSTANT.TERMINATED;
}
export function isExonerated(status) {
    return status === INSURANCE_STATUS_CONSTANT.EXONERATED;
}
export function isExempted(status) {
    return status === INSURANCE_STATUS_CONSTANT.EXEMPTED;
}
const INSURANCE_STATUS = {
    getValues,
    getValuePerCode,
};
export default INSURANCE_STATUS;
