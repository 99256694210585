import { PAYROLL_FILE_CONVERSION_STEPPER_TYPE, PAYROLL_INTEGRATION_TYPES, } from '@/page-layouts/administrator/forms/payroll-files-conversions/utils/form.types';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import LANGUAGE_CODES from 'segic_shared_front_end_utils/src/enums/language-codes';
import { getI18nFromCulture } from 'segic_shared_front_end_utils/src/utils/i18n';
export function formatGroup({ name, mappingCode }) {
    return {
        name: getI18nFromCulture(name),
        mappingCode,
    };
}
export function formatGroupBenefits(rawGroupBenefits) {
    return rawGroupBenefits.map(({ defaultMappingCode, description, id, isDisabled, mappings, prefix, status, }) => ({
        defaultMappingCode,
        description: getI18nFromCulture(description),
        id,
        isDisabled: !!isDisabled,
        prefix,
        mappings,
        status,
    }));
}
export function formatGroupBenefitsMapping({ useCustomMappings, groupBenefits }) {
    return {
        useCustomMappings,
        groupBenefits: groupBenefits ? formatGroupBenefits(groupBenefits) : null,
    };
}
export function formatParticipants(rawParticipants) {
    return rawParticipants ? rawParticipants.map(({ certificateNumber, division, firstName, lastName, mappingCode, participantStatus, }) => ({
        id: certificateNumber,
        certificateNumber,
        division,
        fullName: `${lastName}, ${firstName}`,
        firstName,
        lastName,
        mappingCode: mappingCode || '',
        status: {
            description: getI18nFromCulture(participantStatus.description),
            effectiveDate: participantStatus.effectiveDate,
            isFutureStatus: participantStatus.isFutureStatus,
            status: participantStatus.status,
        },
    })) : null;
}
export function formatParticipantsMapping({ useCertificateNumber, participants }) {
    return {
        useCertificateNumber,
        participants: formatParticipants(participants),
    };
}
export function formatTaxableBenefits(rawTaxableBenefits) {
    return rawTaxableBenefits.map(({ defaultMappingCode, prefix, description, type, mappings, }) => ({
        defaultMappingCode,
        description: getI18nFromCulture(description),
        id: type,
        mappings,
        prefix,
    }));
}
export function formatTaxableBenefitsMapping({ useCustomMappings, taxableBenefits }) {
    return {
        useCustomMappings,
        taxableBenefits: taxableBenefits ? formatTaxableBenefits(taxableBenefits) : null,
    };
}
export function formatRecipients(rawRecipients) {
    return rawRecipients.map(({ divisions, id, integrationType, mappingCode, name, payrollIntegrationId, prefix, }) => ({
        divisions: divisions.map((division) => getI18nFromCulture(division.name)),
        id,
        integrationType,
        mappingCode,
        name: getI18nFromCulture(name),
        payrollIntegrationId,
        prefix: prefix || '',
    }));
}
export function formatPayrollIntegrations(rawPayrollIntegrations) {
    return rawPayrollIntegrations.map(({ description, type, prefix }) => ({
        description: getI18nFromCulture(description),
        prefix,
        type,
    }));
}
export function formatBenefitsOutputMapping(array) {
    return array.map(({ defaultMappingCode, id, isDisabled, mappings, prefix, }) => ({
        defaultMappingCode,
        id,
        isDisabled,
        prefix,
        mappings: mappings.map(({ mappingCode, integrationType, recipientMappingCode, payrollIntegrationId, }) => ({
            mappingCode: isDisabled ? null : mappingCode,
            integrationType,
            recipientMappingCode,
            payrollIntegrationId,
        })),
    }));
}
export function formatTaxableBenefitOutputMapping(array) {
    return array.map(({ defaultMappingCode, id: type, mappings, prefix, }) => ({
        defaultMappingCode,
        type,
        prefix,
        mappings: mappings.map(({ mappingCode, integrationType, recipientMappingCode, payrollIntegrationId, }) => ({
            mappingCode,
            integrationType,
            recipientMappingCode,
            payrollIntegrationId,
        })),
    }));
}
export function isMissingMapping(data, mappingType) {
    if (!data.useCustomMappings) {
        return false;
    }
    return data ? data[mappingType].some((item) => item.mappings.some((mapping) => !mapping.mappingCode && !item?.isDisabled)) : true;
}
export function isMissingParticipantMapping(data) {
    return data ? data.some((item) => !item.mappingCode) : true;
}
export function getParticipantsForOutput(participantsMapping) {
    return participantsMapping.participants && !participantsMapping.useCertificateNumber ? participantsMapping.participants.filter(({ mappingCode }) => mappingCode) : null;
}
export default {
    input({ 
    // eslint-disable-next-line no-shadow
    group, groupBenefitsMapping, participantsMapping, payrollIntegrations, recipients, taxableBenefitsMapping, }) {
        return {
            group: formatGroup(group),
            groupBenefitsMapping: groupBenefitsMapping ? formatGroupBenefitsMapping(groupBenefitsMapping) : null,
            participantsMapping: participantsMapping ? formatParticipantsMapping(participantsMapping) : null,
            payrollIntegrations: formatPayrollIntegrations(payrollIntegrations),
            recipients: formatRecipients(recipients),
            taxableBenefitsMapping: taxableBenefitsMapping ? formatTaxableBenefitsMapping(taxableBenefitsMapping) : null,
            isMissingMapping: {
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.GROUP_BENEFITS_MAPPING]: isMissingMapping(groupBenefitsMapping, 'groupBenefits'),
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.TAXABLE_BENEFITS_MAPPING]: isMissingMapping(taxableBenefitsMapping, 'taxableBenefits'),
                [PAYROLL_FILE_CONVERSION_STEPPER_TYPE.CERTIFICATES_MAPPING]: isMissingParticipantMapping(participantsMapping?.participants),
            },
        };
    },
    output({ content: { 
    // eslint-disable-next-line no-shadow
    recipients, groupBenefitsMapping, participantsMapping, taxableBenefitsMapping, }, }) {
        return {
            groupBenefitsMapping: { useCustomMappings: groupBenefitsMapping.useCustomMappings, groupBenefits: groupBenefitsMapping.useCustomMappings ? formatBenefitsOutputMapping(groupBenefitsMapping.groupBenefits) : null },
            recipients,
            participantsMapping: { useCertificateNumber: participantsMapping.useCertificateNumber, participants: getParticipantsForOutput(participantsMapping) },
            taxableBenefitsMapping: { useCustomMappings: taxableBenefitsMapping.useCustomMappings, taxableBenefits: taxableBenefitsMapping.useCustomMappings ? formatTaxableBenefitOutputMapping(taxableBenefitsMapping.taxableBenefits) : null },
        };
    },
    getIntegrationDataStructure(recipients) {
        return {
            culture: LANGUAGE_CODES.getCodeByLanguage(SETTINGS.LANGUAGE),
            recipients: recipients.map(({ id, integrationType, mappingCode, payrollIntegrationId, }) => ({
                id, integrationType, mappingCode: integrationType !== PAYROLL_INTEGRATION_TYPES.NONE ? mappingCode : null, payrollIntegrationId,
            })),
        };
    },
};
