import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import List from './list/router';
import Single from './single/router';
const routes = [];
let children = [];
children = children.concat(List);
children = children.concat(Single);
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "participants" */ './index.vue');
routes.push({
    name: 'participants',
    path: 'participants',
    redirect: {
        name: 'participants.list',
    },
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_PARTICIPANT_AUTHORIZE_RESOURCE,
        menuTitle: 'planMembers',
        icon: 'people',
    },
});
export default routes;
