import MSAL_CONFIG from '@/msal.config.json';
import * as Msal from '@azure/msal-browser';
import { cloneDeep } from 'lodash';
// With a lot of help from ; https://stackoverflow.com/questions/52944052/creating-a-single-instance-of-a-class-within-a-vue-application
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/UserAgentApplication
// Used the following links for constructing this
// https://github.com/sunilbandla/vue-msal-sample
// https://github.com/kvaes/TasmanianTraders-MSAL-AdvancedVueJsExample
// https://kvaes.wordpress.com/2019/01/16/integration-msal-microsoft-authentication-library-into-vuejs/
class AuthService {
    applicationConfig;
    authorityBase;
    msalObj;
    isLogIn;
    isIdentify;
    auth;
    defaultValue;
    constructor() {
        this.isLogIn = false;
        this.isIdentify = false;
        this.authorityBase = `https://${MSAL_CONFIG.authorityDomain}/${MSAL_CONFIG.scopeDomain}/`;
        this.applicationConfig = {
            auth: {
                clientId: MSAL_CONFIG.clientID,
                authority: `${this.authorityBase}/${MSAL_CONFIG.policyNames.signIn}`,
                knownAuthorities: [MSAL_CONFIG.authorityDomain],
                redirectUri: `${window.location.origin}`,
            },
        };
        this.defaultValue = {
            accountId: null,
            username: null,
            accessToken: null,
            authenticated: null,
            expiresAt: null,
            idToken: sessionStorage.getItem('msal.idtoken'),
            user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null,
        };
        this.auth = cloneDeep(this.defaultValue);
        this.msalObj = new Msal.PublicClientApplication(this.applicationConfig);
    }
    async initializeMsal() {
        try {
            await this.msalObj.initialize();
            const response = await this.msalObj.handleRedirectPromise();
            this.handleRedirect(response);
        }
        catch (error) {
            if (error.errorMessage.includes('AADB2C90118')) {
                this.msalObj.loginRedirect(`${this.authorityBase}${MSAL_CONFIG.policyNames.forgotPassword}`);
            }
            else if (error.errorMessage.includes('AADB2C90091')) {
                this.msalObj.loginRedirect(`${this.authorityBase}/${MSAL_CONFIG.policyNames.signIn}`);
            }
        }
    }
    login() {
        const loginRequest = {
            scopes: ['openid', 'profile'],
        };
        this.msalObj.loginRedirect(loginRequest);
    }
    async getToken(request) {
        await this.initializeMsal();
        request.account = this.msalObj.getAccountByHomeId(this.auth.accountId);
        try {
            const response = await this.msalObj.acquireTokenSilent(request);
            if (!response.accessToken || response.accessToken === '') {
                throw new Msal.InteractionRequiredAuthError();
            }
            this.handleRedirect(response);
            return this.auth.accessToken;
        }
        catch (error) {
            if (error instanceof Msal.InteractionRequiredAuthError) {
                this.msalObj.acquireTokenRedirect(request);
            }
            throw error;
        }
    }
    async getLoginState() {
        await this.initializeMsal();
        if (this.isIdentify) {
            if (this.isLogIn) {
                return this.auth;
            }
            throw new Error('Not logged in');
        }
        else {
            return new Promise((resolve, reject) => {
                const checkLoginState = async () => {
                    // eslint-disable-next-line no-promise-executor-return
                    await new Promise((res) => setTimeout(res, 250));
                    if (this.isLogIn) {
                        resolve(this.auth);
                    }
                    else {
                        reject(new Error('Not logged in'));
                    }
                };
                checkLoginState();
            });
        }
    }
    handleRedirect(response) {
        /**
         * To see the full list of response object properties, visit:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
         */
        if (response) {
            this.handlePolicyChange(response);
            // if response contains an access token, store it
            const auth = {
                accessToken: null,
                authenticated: true,
                expiresAt: response.expiresOn,
                idToken: response.idToken,
                user: response.account,
            };
            if (response.accessToken && response.accessToken !== '') {
                auth.accessToken = response.accessToken;
            }
            sessionStorage.setItem('user', JSON.stringify(auth.user));
            this.auth = auth;
        }
        // for handling B2C user-flows and policies
        this.selectAccount();
    }
    handlePolicyChange(response) {
        /**
         * We need to reject id tokens that were not issued with the default sign-in policy.
         * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
         * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        if (response.idTokenClaims.acr === MSAL_CONFIG.policyNames.editProfile) {
            // eslint-disable-next-line no-alert
            window.alert('Profile has been updated successfully. \nPlease sign-in again.');
            this.msalObj.logout();
        }
        else if (response.idTokenClaims.acr === MSAL_CONFIG.policyNames.forgotPassword) {
            // eslint-disable-next-line no-alert
            window.alert('Password has been reset successfully. \nPlease sign-in with your new password.');
            this.msalObj.logout();
        }
    }
    clearData() {
        sessionStorage.removeItem('user');
        this.auth = this.defaultValue;
    }
    selectAccount() {
        /**
         * See here for more information on account retrieval:
         * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
         */
        const currentAccounts = this.msalObj.getAllAccounts();
        if (currentAccounts.length === 0) {
            this.login();
        }
        else if (currentAccounts.length > 1) {
            console.log('Multiple accounts detected.');
            this.logout();
        }
        else {
            const connectedUser = currentAccounts[0];
            this.auth.accountId = connectedUser.homeAccountId;
            this.auth.username = connectedUser.username;
            this.isLogIn = true;
        }
        this.isIdentify = true;
    }
    logout() {
        /**
         * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
         * https://github.com/AzureAD/microsoft-authentica tion-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
         */
        // Choose which account to logout from by passing a homeAccountId.
        const logoutRequest = {
            account: this.msalObj.getAccountByHomeId(this.auth.accountId),
        };
        this.clearData();
        if (logoutRequest) {
            this.msalObj.logout(logoutRequest);
        }
        else {
            this.msalObj.logout();
        }
    }
}
export default new AuthService();
