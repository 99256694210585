import { deburr, find, forEach, sortBy, } from 'lodash';
import STATUS from '../enums/status';
const disabledId = '3a20a0bb-5066-4060-881f-7681db9aecd5';
const PARTICIPANT_STATUS_DATA = [
    {
        id: '31c3fc03-d3b5-e811-823e-000d3af46b5d',
        description: {
            fr: 'Actif',
            en: 'Active',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: '32c3fc03-d3b5-e811-823e-000d3af46b5d',
        description: {
            fr: 'Terminé',
            en: 'Terminated',
        },
        status: STATUS.CONSTANT.TERMINATED,
    },
    {
        id: 'b8630a33-4e87-48d2-bc2a-008ae555d822',
        description: {
            fr: 'Décédé',
            en: 'Deceased',
        },
        status: STATUS.CONSTANT.TERMINATED,
    },
    {
        id: '8eb65b9a-1fa2-4be9-8a76-34c6320af623',
        description: {
            fr: 'Retraité',
            en: 'Retired',
        },
        status: STATUS.CONSTANT.TERMINATED,
    },
    {
        id: '160d632f-daae-4462-8aed-36c7766b9cce',
        description: {
            fr: 'Cessation emploi',
            en: 'Employment terminated',
        },
        status: STATUS.CONSTANT.TERMINATED,
    },
    {
        id: '5477a091-f1f3-45f4-b990-7efc7fdc3d96',
        description: {
            fr: 'Décédé - Survivants actifs',
            en: 'Deceased - Active survivors',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: 'aebf4001-b6d7-421e-b995-25343abf3d9f',
        description: {
            fr: 'Mise à pied temporaire',
            en: 'Temporary layoff',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: disabledId,
        description: {
            fr: 'Invalidité',
            en: 'Disabled',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: 'b1617714-9b57-417b-8cd4-1b25b89f7c13',
        description: {
            fr: 'Congé sans solde',
            en: 'Unpaid leave of absence',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: '4d63808e-d278-4b5d-992c-a0ede38d2ce1',
        description: {
            fr: 'Congé parental',
            en: 'Parental leave',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
    {
        id: '88e7f8a9-f4a2-4522-88db-538e6391311d',
        description: {
            fr: 'Absence pour maladie',
            en: 'Leave of absence',
        },
        status: STATUS.CONSTANT.ACTIVE,
    },
];
function getParticipantStatusById(statusId, locale) {
    if (!locale) {
        console.warn('No locale getParticipantStatusById');
        return null;
    }
    const status = find(PARTICIPANT_STATUS_DATA, ['id', statusId]);
    if (status) {
        return {
            ...status,
            description: status.description[locale],
            descriptionStatus: STATUS.getValuePerCode(status.status, locale).Value,
        };
    }
    return null;
}
function getStatusDescription(statusId, locale) {
    const status = getParticipantStatusById(statusId, locale);
    return (status) ? status.description : '';
}
export function participantStatusSelectData(locale) {
    const status = [];
    if (!locale) {
        console.warn('No locale');
        return status;
    }
    forEach(PARTICIPANT_STATUS_DATA, (item) => {
        status.push({
            ...item,
            description: item.description[locale],
            statusIconColor: STATUS.getCssClassColor(item.status),
        });
    });
    return sortBy(status, (item) => deburr(item.description));
}
export function participantActiveStatusSelectData(locale) {
    return participantStatusSelectData(locale).filter((status) => status.status === STATUS.CONSTANT.ACTIVE);
}
function isDisabled(id) {
    return id === disabledId;
}
const PARTICIPANT_STATUS = {
    PARTICIPANT_STATUS_DATA,
    getParticipantStatusById,
    getStatusDescription,
    statusSelectData: participantStatusSelectData,
    participantActiveStatusSelectData,
    isDisabled,
};
export default PARTICIPANT_STATUS;
