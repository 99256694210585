import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
export function formatParticipantBenefits(participantBenefits) {
    return participantBenefits.map((participantBenefit) => ({
        ...participantBenefit,
        nextEffectiveDate: formatDate(participantBenefit.nextEffectiveDate),
        history: participantBenefit.history.map((history) => ({
            ...history,
            effectiveDate: formatDate(history.effectiveDate),
        })),
    }));
}
