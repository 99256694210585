export function formatFileName(fileName) {
    return fileName.replace(/'/g, '');
}
export function fileWithNameFormatted(file) {
    return new File([file], formatFileName(file.name), {
        type: file.type,
    });
}
export function outputFormData(data, appendKey = 'file') {
    const formData = new FormData();
    data.forEach((attachment) => {
        formData.append(appendKey, attachment);
    });
    return formData;
}
;
export function outputFormDataWithNameFormatted(data, appendKey = 'file') {
    const formData = new FormData();
    data.forEach((attachment) => {
        const newAttachment = fileWithNameFormatted(attachment);
        formData.append(appendKey, newAttachment);
    });
    return formData;
}
;
