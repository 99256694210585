import messagesEN from '@/locales/en';
import messagesFR from '@/locales/fr';
import { getI18nDictionnary } from 'segic_shared_front_end_utils/src/utils/i18n';
const messages = {
    fr: messagesFR,
    en: messagesEN,
};
export default function tooltipStatus(isFutureStatus, state, date, locale) {
    return isFutureStatus ? getI18nDictionnary(messages, locale).t('common.stateFutureWithDate', { state, date }) : getI18nDictionnary(messages, locale).t('statusWithEffectiveDate', { description: state, effectiveDate: date });
}
