import STATUS_COLORS from '../constants/status-color';
import { getEnumValue, getEnumValues, toValues } from './_utils';
export var ChildStatus;
(function (ChildStatus) {
    ChildStatus[ChildStatus["ACTIVE"] = 1] = "ACTIVE";
    ChildStatus[ChildStatus["TERMINATED"] = 2] = "TERMINATED";
    ChildStatus[ChildStatus["DECEASED"] = 3] = "DECEASED";
    ChildStatus[ChildStatus["DISABLED"] = 4] = "DISABLED";
})(ChildStatus || (ChildStatus = {}));
;
const CONSTANT = {
    ACTIVE: ChildStatus.ACTIVE,
    TERMINATED: ChildStatus.TERMINATED,
    DECEASED: ChildStatus.DECEASED,
    DISABLED: ChildStatus.DISABLED,
};
const values = toValues(CONSTANT);
const FR = {
    [CONSTANT.ACTIVE]: 'Actif',
    [CONSTANT.TERMINATED]: 'Terminé',
    [CONSTANT.DECEASED]: 'Décédé',
    [CONSTANT.DISABLED]: 'Handicapé',
};
const EN = {
    [CONSTANT.ACTIVE]: 'Active',
    [CONSTANT.TERMINATED]: 'Terminated',
    [CONSTANT.DECEASED]: 'Deceased',
    [CONSTANT.DISABLED]: 'Disabled',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
function getValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
export function getChildrenStatusCssClassColor(statusCode) {
    let color = '';
    switch (statusCode) {
        case CONSTANT.ACTIVE:
            color = STATUS_COLORS.GREEN;
            break;
        case CONSTANT.TERMINATED:
            color = STATUS_COLORS.RED;
            break;
        case CONSTANT.DECEASED:
            color = STATUS_COLORS.RED;
            break;
        case CONSTANT.DISABLED:
            color = STATUS_COLORS.GREEN;
            break;
        default:
            color = '';
    }
    return color;
}
const CHILDREN_STATUS = {
    CONSTANT,
    getValues,
    getValuePerCode,
};
export default CHILDREN_STATUS;
