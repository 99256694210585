import { isFunction } from 'segic_shared_front_end_utils/src/utils/types';
export default {
    data() {
        return {
            isInitializeStore: false,
        };
    },
    mounted() {
        this.startLoadProcess();
    },
    beforeDestroy() {
        this.endLoadProcess();
    },
    watch: {
        '$route.params': {
            handler(newData, oldData) {
                if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                    this.startLoadProcess();
                }
            },
            deep: true,
        },
        '$route.query': function queryWatcherHandler(newData, oldData) {
            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                this.startLoadProcess();
            }
        },
    },
    methods: {
        isReadyToLoad() {
            return true;
        },
        startLoadProcess() {
            this.endLoadProcess();
            this.startStore();
            this.startLoad();
        },
        endLoadProcess() {
            if (this.isInitializeStore) {
                if (isFunction(this.destroyStore)) {
                    this.destroyStore();
                }
                else {
                    console.warn('missing :: destroyStore method');
                }
            }
        },
        startStore() {
            if (this.isReadyToLoad()) {
                if (!this.isInitializeStore && isFunction(this.initializeStore)) {
                    this.initializeStore();
                }
            }
        },
        startLoad() {
            if (this.isReadyToLoad()) {
                if (isFunction(this.startLoadingData)) {
                    this.startLoadingData();
                }
            }
        },
    },
};
