import { DateTime } from 'luxon';
import { NULL_DATE_TIME } from '../constants/null';
import { capitalizeFirstLetter } from './string';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_LUXON = 'y-MM-dd';
export const DATE_FORMAT_LUXON_NO_SEPARATOR = 'yMMdd';
export const DATE_FORMAT_IN_MONTH_LUXON = 'y-MM';
export const DATE_FORMAT_IN_YEAR_LUXON = 'y';
export const DATE_FORMAT_MONTH_TEXT_LUXON = 'MMMM';
export const DATE_FORMAT_DATE_TIME_LUXON = 'y-MM-dd HH:mm:ss';
const DATA_FORMAT_I18N = {
    en: {
        monthDate: 'MMMM d',
        monthDateYear: 'MMMM d, y',
        shortMonthDate: 'MMM dd',
    },
    fr: {
        monthDate: 'd MMMM',
        monthDateYear: 'd MMMM y',
        shortMonthDate: 'dd MMM',
    },
};
// type DateObject = string | null | Date;
export function isDateJSValid(date) {
    return DateTime.fromJSDate(date).isValid;
}
export function isDateValid(date) {
    if (date === NULL_DATE_TIME) {
        return false;
    }
    return DateTime.fromISO(date).isValid || isDateJSValid(date);
}
export function isFullDateValid(date) {
    return DateTime.fromISO(date).isValid && DateTime.fromFormat(date, 'yyyy-MM-dd', { strict: true }).isValid;
}
export function formatDate(date, emptyValue = null, format = DATE_FORMAT_LUXON) {
    if (isDateJSValid(date)) {
        return DateTime.fromJSDate(date).toFormat(format);
    }
    return isDateValid(date) ? DateTime.fromISO(date).toFormat(format) : emptyValue;
}
export function formatDateAndTime(date, emptyValue = null) {
    let newDate = date;
    if (typeof date === 'string') {
        if (date.indexOf('T') !== -1 && date.indexOf('Z') === -1) {
            newDate = `${date}Z`;
        }
    }
    return formatDate(newDate, emptyValue, DATE_FORMAT_DATE_TIME_LUXON);
}
export function formatMonthAndYear(date, emptyValue = null) {
    return formatDate(date, emptyValue, DATE_FORMAT_IN_MONTH_LUXON);
}
export function formatMonthAndDateForDisplay(date, locale, emptyValue = null) {
    if (isDateValid(date)) {
        return DateTime.fromISO(date).setLocale(locale).toFormat(DATA_FORMAT_I18N[locale].monthDate);
    }
    return emptyValue;
}
export function formatMonthDateYear(date, locale, emptyValue = null) {
    if (isDateValid(date)) {
        return DateTime.fromISO(date).setLocale(locale).toFormat(DATA_FORMAT_I18N[locale].monthDateYear);
    }
    return emptyValue;
}
// Oct 02 '24
export function formatShortMonthDateYear(date, locale, emptyValue = null) {
    if (isDateValid(DateTime.fromISO(date))) {
        const monthDay = DateTime.fromISO(date).setLocale(locale).toFormat(DATA_FORMAT_I18N[locale].shortMonthDate);
        return `${monthDay} '${formatDate(date, emptyValue, 'yy')}`;
    }
    return emptyValue;
}
export function formatYear(date, emptyValue = null) {
    const year = formatDate(date, emptyValue, DATE_FORMAT_IN_YEAR_LUXON);
    return year !== emptyValue ? parseInt(year, 10) : year;
}
export function formatMonth(date) {
    return formatDate(date, null, 'MM');
}
export function getMonthText(date, locale, emptyValue = null) {
    if (isDateValid(date)) {
        const dateFormatted = DateTime.fromISO(date).setLocale(locale).toFormat(DATE_FORMAT_MONTH_TEXT_LUXON);
        // In french, luxon doesn't capitalize the first letter of the month
        return capitalizeFirstLetter(dateFormatted);
    }
    return emptyValue;
}
export function now() {
    return DateTime.now();
}
export function today() {
    return formatDate(DateTime.now());
}
export function tomorrow() {
    return addDaysToDate(DateTime.now(), 1);
}
export function currentYear() {
    return DateTime.now().year;
}
export function currentMonthYear() {
    return formatMonthAndYear(DateTime.now());
}
export function currentMonthIndex() {
    return DateTime.now().month - 1;
}
export function currentDay() {
    return DateTime.now().day;
}
export function addDaysToDate(date, days) {
    return formatDate(DateTime.fromISO(date).plus({ days }));
}
export function subtractYearsToDate(date, years) {
    if (Number(years)) {
        return formatDate(DateTime.fromISO(date).minus({ years }));
    }
    return formatDate(date);
}
export function subtractYearsToDateAndReturnYear(date, years) {
    return subtractYearsToDate(date, years);
}
export function subtractDaysToNow(days) {
    return formatDate(DateTime.now().minus({ days }));
}
export function subtractDaysToDate(date, days) {
    if (Number(days)) {
        return formatDate(DateTime.fromISO(date).minus({ days }));
    }
    return formatDate(date);
}
export function subtractMonthsToDate(date, months) {
    if (Number(months)) {
        return formatDate(DateTime.fromISO(date).minus({ months }));
    }
    return formatDate(date);
}
export function subtractMonthsToDateAndReturnMonthDateYear(date, months) {
    return formatMonthAndYear(subtractMonthsToDate(date, months));
}
export function subtractMonthsToNow(months) {
    return subtractMonthsToDate(DateTime.now(), months);
}
export function subtractYearsToNow(years) {
    return formatDate(DateTime.now().minus({ years }));
}
export function subtractYearsToNowReturnYear(years) {
    return formatYear(subtractYearsToNow(years));
}
export function addDaysToNow(days) {
    return addDaysToDate(DateTime.now(), days);
}
export function addMonthsToDate(date, months) {
    return formatDate(DateTime.fromISO(date).plus({ months }));
}
export function addMonthsToDateAndFormatInMonth(date, months) {
    const newDate = addMonthsToDate(date, months);
    if (newDate) {
        return DateTime.fromISO(newDate).toFormat(DATE_FORMAT_IN_MONTH_LUXON);
    }
    return newDate;
}
export function addMonthsToNow(months) {
    return addMonthsToDate(DateTime.now(), months);
}
export function addYearsToDate(date, years) {
    return formatDate(DateTime.fromISO(date).plus({ years }));
}
export function addYearsToNow(years) {
    return addYearsToDate(DateTime.now(), years);
}
export function addYearsToNowAndReturnYear(years) {
    return formatYear(addYearsToNow(years));
}
export function addYearsToDateAndFormatInYear(date, years) {
    const newDate = addYearsToDate(date, years);
    if (newDate) {
        return DateTime.fromISO(newDate).toFormat(DATE_FORMAT_IN_YEAR_LUXON);
    }
    return newDate;
}
export function isDatesSameYear(date1, date2) {
    return DateTime.fromISO(date1).hasSame(DateTime.fromISO(date2), 'year');
}
export function isSameDate(date1, date2) {
    return DateTime.fromISO(date1).hasSame(DateTime.fromISO(date2), 'days');
}
export function difference(startDate, endDate) {
    let end = DateTime.fromISO(endDate);
    let start = DateTime.fromISO(startDate);
    return end.diff(start, ['years', 'months', 'days', 'minutes']).toObject();
}
export function differenceInYears(date, dateToCompare, emptyValue = null) {
    if (!date || !dateToCompare) {
        return emptyValue;
    }
    return difference(dateToCompare, date).years;
}
export function differenceInMilliseconds(date, date2, emptyValue = null) {
    if (!date || !date2) {
        return emptyValue;
    }
    return getUnixTimestamp(date) - getUnixTimestamp(date2);
}
export function differenceFromNowInDays(date) {
    const newDate = formatDate(date);
    const dateNow = formatDate(DateTime.now());
    return DateTime.fromISO(newDate).diff(DateTime.fromISO(dateNow), ['days']).toObject().days;
}
export function isDateBeforeNow(date) {
    return DateTime.fromISO(date) < DateTime.fromISO(today());
}
export function isDateSameOrBeforeNow(date) {
    return DateTime.fromISO(date) <= DateTime.fromISO(today());
}
export function isDateSameOrBeforeAnotherDate(date1, date2) {
    const d1 = DateTime.fromISO(date1);
    const d2 = DateTime.fromISO(date2);
    return d1 <= d2;
}
export function isDateBeforeAnotherDate(date1, date2) {
    const d1 = DateTime.fromISO(date1);
    const d2 = DateTime.fromISO(date2);
    return d1 < d2;
}
export function isDateAfterNow(date) {
    return DateTime.fromISO(date) > DateTime.fromISO(today());
}
export function isDateSameOrAfterAnotherDate(date1, date2) {
    const d1 = DateTime.fromISO(date1);
    const d2 = DateTime.fromISO(date2);
    return d1 >= d2;
}
export function isDateAfterAnotherDate(date1, date2) {
    const d1 = DateTime.fromISO(date1);
    const d2 = DateTime.fromISO(date2);
    return d1 > d2;
}
export function isDateSameOrAfterNow(date) {
    return isDateSameOrAfterAnotherDate(today(), date);
}
export function isDateSameDay(date1, date2) {
    const d1 = DateTime.fromISO(date1);
    const d2 = DateTime.fromISO(date2);
    return d1.hasSame(d2, 'day');
}
export function isNotDateSameDay(date1, date2) {
    if (!date1 || !date2) {
        return null;
    }
    return !isDateSameDay(date1, date2);
}
export function getUnixTimestamp(date) {
    const formattedDate = formatDate(date, null, 'x');
    if (formattedDate) {
        return parseInt(formattedDate, 10);
    }
    return formattedDate;
}
export function getUnixTimestampFromNow() {
    return getUnixTimestamp(DateTime.now());
}
export function addMinutesToUnixTimestampNow(minutes) {
    return DateTime.fromISO(DateTime.now()).plus({ minutes }).toMillis();
}
export function isUnixTimestampIsBeforeNow(timestamp) {
    return timestamp < getUnixTimestampFromNow();
}
export function isTimestampInFuture(timestamp, minutes) {
    const dateTime = DateTime.fromMillis(timestamp);
    const futureDateTime = dateTime.plus({ minutes }).toMillis();
    return futureDateTime > getUnixTimestampFromNow();
}
export function isCivilYear(date, emptyValue = null) {
    return formatDate(date, emptyValue, 'MMdd') === '0101';
}
export function dateEndOfYear(date) {
    const endOfYear = formatDate(DateTime.fromISO(date).endOf('year'));
    return endOfYear ? endOfYear.toString() : endOfYear;
}
export function dateEndOfMonth(date) {
    const endOfMonth = formatDate(DateTime.fromISO(date).endOf('month'));
    return endOfMonth ? endOfMonth.toString() : endOfMonth;
}
