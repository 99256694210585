import { forEach } from 'lodash';
import LOGGER from 'segic_shared_front_end_utils/src/utils/debugging';
class LocalStorageManager {
    constructor() {
        this.removeOld();
    }
    static detect() {
        const customkey = '2jjn1od1i02md-srj9w';
        try {
            localStorage.setItem(customkey, customkey);
            localStorage.removeItem(customkey);
            return true;
        }
        catch (e) {
            return false;
        }
    }
    set(c_name, c_val, expire_day) {
        const value = {};
        value.value = c_val;
        let date = null;
        if (expire_day) {
            date = new Date();
            date.setDate(date.getDate() + expire_day);
        }
        value.expire = date;
        try {
            const data = JSON.stringify(value);
            localStorage.setItem(c_name, data);
        }
        catch (err) {
            LOGGER.error(err);
        }
        return this;
    }
    get(c_name, raw) {
        let item = null;
        if (localStorage.getItem(c_name)) {
            if (raw) {
                item = JSON.parse(localStorage.getItem(c_name));
            }
            else {
                item = JSON.parse(localStorage.getItem(c_name)).value;
            }
        }
        return item;
    }
    remove(c_name) {
        localStorage.removeItem(c_name);
        return this;
    }
    removeOld() {
        const scope = this;
        forEach(localStorage, (val, index) => {
            if (val && val.expire != undefined) {
                const now = new Date();
                if (now > val.expire) {
                    scope.remove(index);
                }
            }
        });
    }
}
const localStorageManager = (LocalStorageManager.detect()) ? new LocalStorageManager() : null;
export default localStorageManager;
