import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import List from './list/router';
const routes = [];
let children = [];
children = children.concat(List);
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "invoices" */ './index.vue');
routes.push({
    name: 'invoices',
    path: 'invoices',
    redirect: {
        name: 'invoices.list',
    },
    components,
    children,
    meta: {
        resourcePermission: SECURITY_PERMISSIONS.VIEW,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.EMPLOYER_BILLING_AUTHORIZE_RESOURCE,
        menuTitle: 'invoices',
        icon: 'receipt',
    },
});
export default routes;
