import { filter, find, forEach, merge, } from 'lodash';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import EMPLOYE_PORTAL_CODES from './codes/employer-portal';
const SECURITY_CODES = merge({}, EMPLOYE_PORTAL_CODES);
export function checkAccess(permissionLevel, securableResources, userPermissions, params) {
    let hasPermission = false;
    const userPermissionsData = (this && this.userPermissions) ? this.userPermissions : userPermissions;
    const debug = (params && params.debug);
    if (debug && !userPermissionsData) {
        console.warn('pass userPermissions to your checkAccess');
        return;
    }
    if (userPermissionsData && SECURITY_CODES[securableResources]) {
        const id = SECURITY_CODES[securableResources];
        if (id) {
            let permissions = filter(userPermissionsData, { resourceId: id });
            if (permissions) {
                let clientGuid = null;
                let groupGuid = null;
                if (params && typeof params.clientGuid !== 'undefined' && typeof params.groupGuid !== 'undefined') {
                    clientGuid = params.clientGuid;
                    groupGuid = params.groupGuid;
                }
                else if (this && this.$route?.params) {
                    clientGuid = this.$route.params.clientGuid;
                    groupGuid = this.$route.params.groupGuid;
                }
                if (debug) {
                    console.log('----------------------------------------------');
                    console.log(`resourceName: ${securableResources}`);
                    console.log(`resourceId: ${id}`);
                    console.log(`clientGuid: ${clientGuid}`);
                    console.log(`groupGuid: ${groupGuid}`);
                    console.log(permissions);
                }
                if (clientGuid || groupGuid) {
                    const allPermissionsList = filter(permissions, { clientId: null, groupId: null });
                    if (allPermissionsList.length !== 0) {
                        if (debug) {
                            console.log('Permissions found:: clientGuid:null, groupGuid:null', allPermissionsList);
                        }
                        permissions = allPermissionsList;
                    }
                    else {
                        const clientsPermissionsOnlyList = filter(permissions, {
                            clientId: clientGuid,
                            groupId: null,
                        });
                        const clientsGroupsPermissionsList = filter(permissions, {
                            clientId: clientGuid,
                            groupId: groupGuid,
                        });
                        if (debug) {
                            if (clientsPermissionsOnlyList.length !== 0) {
                                console.log(`Permissions found:: clientGuid:${clientGuid}, groupGuid:null`, clientsPermissionsOnlyList);
                            }
                            if (clientsGroupsPermissionsList.length !== 0) {
                                console.log(`Permissions found:: clientGuid:${clientGuid}, groupGuid:${groupGuid}`, clientsGroupsPermissionsList);
                            }
                        }
                        if (clientsPermissionsOnlyList.length !== 0) {
                            permissions = clientsPermissionsOnlyList;
                        }
                        if (clientsGroupsPermissionsList.length !== 0) {
                            permissions = clientsGroupsPermissionsList;
                        }
                        if (clientsPermissionsOnlyList.length === 0 && clientsGroupsPermissionsList.length === 0) {
                            permissions = [];
                        }
                    }
                }
                permissions = find(permissions, (item) => permissionLevel <= item.permissions);
                hasPermission = typeof permissions !== 'undefined';
            }
        }
    }
    // eslint-disable-next-line consistent-return
    return hasPermission;
}
export default {
    data() {
        return {
            SECURITY_CODES,
        };
    },
    computed: {
        userPermissions() {
            return (this.userStore) ? this.userStore.getGetter('security') : [];
        },
        canEdit() {
            const { resource } = this.$route.meta;
            return this.checkAccess(SECURITY_PERMISSIONS.VIEW_EDIT, resource);
        },
        canView() {
            const { resource } = this.$route.meta;
            return this.checkAccess(SECURITY_PERMISSIONS.VIEW, resource);
        },
    },
    methods: {
        checkAccess,
        // null, null // GroupSecurityResource Edit
        // med, null  // GroupSecurityResource Edit
        // med, 11orb // GroupSecurityResource Edit
        hasAtLeastOneGroupWithNull(resource, permissionToCheck) {
            const resourceId = this.SECURITY_CODES[resource];
            const clients = [];
            if (this && this.userPermissions && resourceId) {
                forEach(this.userStore.getGetter('clients'), (client) => {
                    const groups = filter(this.userPermissions, (permission) => {
                        const itemPermission = permission.Permissions.toLowerCase().replace(/ /g, '').split(',');
                        const hasEdit = itemPermission.indexOf(permissionToCheck.toLowerCase()) !== -1;
                        return (permission.clientId === null || permission.clientId === client.Id) && permission.groupId === null && permission.ResourceId === resourceId && hasEdit;
                    });
                    clients.push({
                        id: client.Id,
                        hasGroupWithNull: groups.length !== 0,
                        ResourceId: resourceId,
                    });
                });
            }
            return filter(clients, { hasGroupWithNull: true }).length !== 0;
        },
    },
};
