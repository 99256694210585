export default {
    annualEarnings: 'Annual earnings',
    appliesToThisPlanMember: 'Applies to this plan member',
    automaticEnrollment: 'Automatic enrollment',
    automaticEnrollmentDate: 'Automatic enrollment date',
    autoCreation: 'Auto-creation',
    automaticCreationDate: 'Automatic creation date',
    birthDate: 'Birth date',
    cellphone: 'Cellphone',
    certificate: 'Certificate',
    communicationLanguage: 'Communication language',
    completedFileToImport: 'Completed file to import *',
    coverageEffectiveDate: 'Coverage effective date',
    creationDate: 'Automatic enrollment',
    downloadTemplate: 'Download template',
    email: 'Email',
    emailDeliveryMayTakeAMoment: 'Email delivery may take a moment.',
    emailReminderDate: 'Email reminder date',
    employmentProvince: 'Employment province',
    failed: 'Failed',
    estimatedDateOfReturnToWork: 'Estimated date of return to work',
    fixTheErrorsBelowInTheSourceFileAndValidateTheFileAgain: 'Fix the errors below in the source file and validate the file again.',
    followUp: 'Follow-up',
    from: 'From',
    gender: 'Gender',
    grouping: 'Group',
    groupsFileTemplate: 'Group\'s file template',
    hiringDate: 'Hiring date',
    hiringDateCoverageEffectiveDate: 'Hiring date<br/>Coverage effective date',
    ifAnEmailCantBeDeliveredTheMembersOnboardingStatusWillBeSetToBounced: 'If an email can\'t be delivered, the member\'s Onboarding status will be set to Bounced.',
    importPlanMembers: 'Import plan members',
    invitationEmailsWillBeSentImmediatelyRegardlessOfCoverageEffectiveDates: 'Invitation emails will be sent immediately, regardless of coverage effective dates.',
    inviteMembers: 'Invite members',
    inviteNewPlanMembers: 'Invite new plan members',
    inviteNewPlanMembersToOnboarding: 'Invite new plan members to onboard',
    invitePlanMember: 'Invite plan member',
    invitePlanMembers: 'Invite plan members',
    language: 'Language',
    lastNameFirstNameEmail: 'Last name, First name<br/>Email',
    line: 'Line',
    makeSureYouHaveSelectedTheCorrectFileThatItOpensWithoutErrorsInExcelAndThatItFollowsTheTemplateFormatAsSpecified: 'Make sure you have selected the correct file, that it opens without errors in Excel, and that it follows the template format as specified.',
    modificationToTheProtectionsOnSeveralBenefitsIsOnlyPossibleOnTheActiveBenefitsToModifyTheTerminatedBenefitMakeTheModificationOnTheBenefitItself: 'Modification to the protections on several benefits is only possible on the active benefits. To modify the terminated benefit, make the modification on the benefit itself',
    mustBeInExcelFormatXlsx: 'Must be in Excel format (.xlsx)',
    nErrors: 'error | errors',
    nInvitation: 'invitation | invitations',
    noInvitationsWereSent: 'No invitations were sent.',
    notes: 'Notes',
    occupationAnnualSalary: 'Occupation<br/>Annual Salary',
    onboarding: 'Onboarding',
    payrollDeductionFile: 'Payroll deduction file',
    reminderEmail: 'Reminder email',
    scheduled: 'Scheduled',
    proofSchoolAttendanceRequired: 'Proof of school attendance required',
    selectTheExcelFileContainingNewMembersToImport: 'Select the Excel file containing new members to import',
    sendInvitations: 'Send invitations?',
    sendTheInvitation: 'Send the invitation',
    sent: 'Sent',
    sentOn: 'Sent on',
    status: 'Status',
    theFileContainsFormatErrors: 'The file contains format errors',
    theFileIsUnreadableOrCorruptedOrIsNotInTheExpectedExcelFormat: 'The file is unreadable or corrupted, or is not in the expected Excel format',
    theFileIsValid: 'The file is valid.',
    theInitialInvitationEmailIsBeingSent: 'The initial invitation email is being sent.',
    to: 'To',
    validateFile: 'Validate file',
    willBeSent: 'will be sent | will be sent',
};
