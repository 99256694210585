import { Validator } from 'vee-validate';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Dictionaries from '../utils/vue-i18n';
Vue.use(VueI18n);
const defaultLocale = process.env.VUE_APP_I18N_LOCALE || 'fr';
const I18n = new VueI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages: Dictionaries,
    silentTranslationWarn: true,
});
Vue.prototype.$locale = {
    change(lang) {
        I18n.locale = lang;
        Validator.localize(lang);
    },
};
export default I18n;
