import { getEnumValue, getEnumValues, toValuesEnumLowerCase } from './_utils';
export var LANGUAGE_CODES_TYPES;
(function (LANGUAGE_CODES_TYPES) {
    LANGUAGE_CODES_TYPES[LANGUAGE_CODES_TYPES["FR"] = 1] = "FR";
    LANGUAGE_CODES_TYPES[LANGUAGE_CODES_TYPES["EN"] = 2] = "EN";
})(LANGUAGE_CODES_TYPES || (LANGUAGE_CODES_TYPES = {}));
;
const values = toValuesEnumLowerCase(LANGUAGE_CODES_TYPES);
const FR = {
    [LANGUAGE_CODES_TYPES.FR]: 'Français',
    [LANGUAGE_CODES_TYPES.EN]: 'English',
};
const EN = {
    [LANGUAGE_CODES_TYPES.FR]: 'Français',
    [LANGUAGE_CODES_TYPES.EN]: 'English',
};
const Dictionaries = {
    fr: FR,
    en: EN,
};
export function languageCodesGetValues(lang) {
    return getEnumValues(values, Dictionaries, lang);
}
function getValuePerCode(code, lang) {
    return getEnumValue(values, Dictionaries, lang, code);
}
function getCodeByLanguage(lang) {
    const code = LANGUAGE_CODES_TYPES[lang.toUpperCase()];
    return code || null;
}
const LANGUAGE_CODES = {
    CONSTANT: LANGUAGE_CODES_TYPES,
    getValues: languageCodesGetValues,
    getValuePerCode,
    getCodeByLanguage,
};
export default LANGUAGE_CODES;
