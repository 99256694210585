export default {
    currency: '${currency}',
    download: 'Download',
    effectiveDateFuture: 'Effective date',
    error: 'An error has occurred.',
    insuredCapitalLabels: {
        fixedAmount: 'Fixed amount at {amount}',
        salaryMultipleWeekly: '{times} times the annual weekly',
        salaryMultipleMonthly: '{times} times the monthly salary',
        salaryMultipleYearly: '{times} times the annual salary',
        unitValue: 'Units of {units}',
        firstPercentage: 'of the first',
        remaining: 'of the rest',
        nextPercentage: 'of the next',
        salaryPercentageWeekly: '{percentage}% of the weekly salary',
        salaryPercentageMonthly: '{percentage}% of the monthly salary',
        salaryPercentageYearly: '{percentage}% of the yearly salary',
    },
    label: 'Label',
    next: 'Next',
    previous: 'Previous',
    proofSchoolAttendance: 'Proof of school attendance',
    protection: 'Coverage',
    reinitialize: 'Reinitialize',
    removeWaitingPeriod: 'Remove waiting period',
    residentialProvince: 'Residential province',
    requestedChange: 'Requested change',
    recipient: 'Payroll deductions file',
    referenceNumber: 'Reference number',
    requestsChange: 'Change request',
    requestDescription: 'Request description',
    requestNumber: 'Request number',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    send: 'Send',
    sendRequest: 'Send request',
    sendFiles: 'Send files',
    sendNewProofSchoolAttendance: 'Send a new proof of school attendance',
    simulationDate: 'Simulation date',
    since: 'Since',
    smokerStatus: 'Smoker status',
    socialInsuranceNumber: 'SIN',
    spouse: 'Spouse',
    spouseChildAmount: 'Spouse: {spouseAmount} <br /> Child: {childAmount}',
    spouseMan: 'Spouse',
    spouseWoman: 'Spouse',
    start: 'Start',
    statusRequest: 'Request status',
    stateFutureWithDate: '{state} at {date}',
    type: 'Type',
    willBeActive: '<span class="caption--text">Will be active on</span> <span class="title_color--text font-weight-bold">{effectiveDate}</span>',
    work: 'Work',
    workProvince: 'Province of employment',
    yes: 'Yes',
};
