import { clone, orderBy } from 'lodash';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import STATUS from 'segic_shared_front_end_utils/src/enums/status';
import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
import { getI18nFromCulture } from 'segic_shared_front_end_utils/src/utils/i18n';
export default {
    input(data) {
        data.Text = getI18nFromCulture(data.Text);
        data.NextStatusEffectiveDate = data.NextStatusEffectiveDate ? formatDate(data.NextStatusEffectiveDate) : data.NextStatusEffectiveDate;
        data.EffectiveDate = formatDate(data.StatusEffectiveDate);
        data.Recipients = data.Recipients.map((item) => {
            item.Recipient.Description = getI18nFromCulture(item.Recipient.Description);
            return item;
        });
        data.CodeDescription = {
            fr: `${data.Code} - ${data.Text.fr}`,
            en: `${data.Code} - ${data.Text.en}`,
        };
        data.Subtitle = STATUS.getFutureStatus(clone(data.Text), data.NextStatusEffectiveDate, data.NextStatus);
        data.StatusIconColor = STATUS.getCssClassColor(data.NextStatus === null ? data.Status : data.NextStatus);
        data.StatusIcon = STATUS.getStatusIconName(data.NextStatus);
        delete data.StatusEffectiveDate;
        return data;
    },
    display(data) {
        data.Recipients = data.Recipients.map((item) => {
            item.Recipient.Description = item.Recipient.Description[SETTINGS.LANGUAGE];
            return item;
        });
        data.Recipients = orderBy(data.Recipients, ['Description']);
        return data;
    },
    inputHistory(data) {
        return {
            EffectiveDate: formatDate(data.EffectiveDate),
            Status: data.Status.Code,
        };
    },
};
