import { formatChildren } from '@/store/plan-member/formatter/children';
import { formatParticipantBenefits } from '@/store/plan-member/formatter/participantBenefits';
import { formatPlanMemberStatus } from '@/store/plan-member/formatter/status';
import { sortBy } from 'lodash';
export default {
    input(planMember) {
        return {
            ...planMember,
            children: sortBy(formatChildren(planMember.children), ['sortedType', 'person.birthDate']),
            benefits: formatParticipantBenefits(planMember.benefits),
            status: formatPlanMemberStatus(planMember.status),
        };
    },
};
