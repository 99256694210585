import { render, staticRenderFns } from "./logo.vue?vue&type=template&id=01396665"
import script from "./logo.vue?vue&type=script&lang=ts"
export * from "./logo.vue?vue&type=script&lang=ts"
import style0 from "./logo.vue?vue&type=style&index=0&id=01396665&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
