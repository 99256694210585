import Store from '@/store/index';
import { guid } from '@/utils/helpers';
import { cloneDeep, forEach, merge } from 'lodash';
import { replaceStringBrackets, replaceStringData, replaceStringRoutingData } from 'segic_shared_front_end_utils/src/utils/routing';
import { isFunction } from 'segic_shared_front_end_utils/src/utils/types';
function getModule(key) {
    return (Store.state[key]);
}
export default class StoreExtender {
    view;
    namespace;
    regexnamespace;
    module;
    options;
    constructor(view, namespace, module, options = {}) {
        this.view = view;
        this.options = options;
        this.regexnamespace = namespace;
        const routeParams = (this.view?.$route?.params) ? this.view.$route.params : {};
        const params = merge({}, routeParams, this.options.extraParams);
        this.namespace = replaceStringData(namespace, params);
        this.namespace = replaceStringRoutingData(this.namespace, params);
        this.namespace = replaceStringBrackets(this.namespace);
        if (this.options.unique) {
            this.namespace += `(UID:${guid()})`;
        }
        if (module && Store) {
            this.module = module;
            this.createModule();
            this.addListeners();
        }
    }
    createModule() {
        if (!getModule(this.namespace)) {
            if (this.options.debug) {
                console.log('createModule', this.namespace, this.module);
            }
            Store.registerModule(this.namespace, cloneDeep(this.module));
        }
    }
    addListeners() {
        if (!Store.state.storesListeners[this.namespace]) {
            Store.state.storesListeners[this.namespace] = 1;
        }
        else {
            Store.state.storesListeners[this.namespace] += 1;
        }
    }
    destroyModule() {
        if (Store.state.storesListeners[this.namespace]) {
            Store.state.storesListeners[this.namespace] -= 1;
            if (getModule(this.namespace) && Store.state.storesListeners[this.namespace] === 0) {
                Store.unregisterModule(this.namespace);
            }
        }
    }
    getState(key, nowarn) {
        const namespacedObject = this.namespace.split('/');
        let finalKey = Store.state;
        let isFound = true;
        forEach(namespacedObject, (namespaceBlock) => {
            if (typeof finalKey[namespaceBlock] === 'undefined') {
                isFound = false;
                return false;
            }
            finalKey = finalKey[namespaceBlock];
            return true;
        });
        if (!isFound) {
            if (!nowarn) {
                console.warn('Verify your store namespace:', this.namespace);
            }
            return false;
        }
        return (key) ? finalKey[key] : finalKey;
    }
    getGetter(key, data) {
        let getter = null;
        if (typeof Store.getters[`${this.namespace}/${key}`] !== 'undefined') {
            const refKey = `${this.namespace}|${key}`;
            const storeRef = Store.state.storeReferences[refKey];
            const storeCache = Store.state.storesCaching[storeRef];
            if (storeCache) {
                const getterData = cloneDeep(storeCache);
                if (data) {
                    getterData.getterParams = data;
                }
                getter = Store.getters[`${this.namespace}/${key}`](getterData);
            }
            else {
                let finalData = data;
                if (isFunction(Store.getters[`${this.namespace}/${key}`]) && !finalData) {
                    finalData = {};
                }
                if (finalData) {
                    getter = Store.getters[`${this.namespace}/${key}`](finalData);
                }
                else {
                    getter = Store.getters[`${this.namespace}/${key}`];
                }
            }
        }
        return getter;
    }
    sendMutations(key, payload) {
        return Store.commit(`${this.namespace}/${key}`, payload);
    }
    sendActions(key, payload) {
        const finalPayload = {
            actionNamespace: this.namespace,
            actionRegexNamespace: this.regexnamespace,
            options: this.options,
            actionKey: key,
            data: cloneDeep(payload),
            scope: this.view ? this.view : null,
            params: {},
            query: {},
            debug: this.options.debug,
        };
        if (finalPayload.scope) {
            finalPayload.params = merge({}, finalPayload.params, finalPayload.scope.$route.params, this.options.extraParams);
            finalPayload.query = merge({}, finalPayload.query, finalPayload.scope.$route.query, this.options.extraQuery);
        }
        if (payload && typeof payload.params !== 'undefined') {
            finalPayload.params = merge({}, finalPayload.params, payload.params);
        }
        if (payload && typeof payload.query !== 'undefined') {
            finalPayload.query = merge({}, finalPayload.query, payload.query);
        }
        if (payload && typeof payload.data !== 'undefined') {
            finalPayload.data = cloneDeep(payload.data);
        }
        if (this.options.debug) {
            console.log('sendActions', this.namespace, finalPayload);
        }
        const action = Store.dispatch(`${this.namespace}/${key}`, finalPayload);
        const promiseGet = this.getGetter('getPromise', key);
        if (promiseGet) {
            if (Store.state['global|loader'] && Store.state['global|loader'].isLoading) {
                Store.dispatch('global|loader/add', promiseGet);
            }
        }
        return (promiseGet) || action;
    }
    cancelActions(key) {
        const promiseGet = this.getGetter('getPromise', key);
        if (promiseGet) {
            promiseGet.reject(0);
        }
        return promiseGet;
    }
}
