import { NULL_GUID } from 'segic_shared_front_end_utils/src/constants/null';
import { today } from 'segic_shared_front_end_utils/src/utils/date';
function getDefaultData() {
    return {
        frequency: {
            Code: '',
            Value: '',
        },
        participant: {
            id: null,
            age: '',
            birthDate: '',
            categoryId: null,
            certificateNumber: '',
            coveredPerson: {
                Code: '',
                Value: '',
            },
            gender: {
                Code: '',
                Value: '',
            },
            smokerStatus: {
                Code: '',
                Value: '',
            },
            salary: 0,
            statusId: '',
            indianStatus: false,
            provinceId: null,
            workProvinceId: null,
            recipientId: null,
            spouseAge: '',
            spouseBirthDate: '',
            spouseGender: {
                Code: '',
                Value: '',
            },
            spouseSmokerStatus: {
                Code: '',
                Value: '',
            },
            participantGuid: '',
        },
        simulationDate: today(),
        taxableBenefits: {},
        mandatoryBenefits: [],
        optionalBenefits: [],
    };
}
function getParticipantFormDefaultData() {
    return {
        participant: {
            certificateNumber: '',
            description: '',
            id: '',
        },
        simulationDate: today(),
    };
}
function getInitialData({ simulationDate, participant: { id: participantGuid, certificateNumber, StatusId: statusId, }, simulationPlanMember: { profile: { age, birthDate, category, gender, firstNationStatus, residentialProvince, provinceOfEmployment, annualSalary, smokerStatus, recipientId, spouseAge, spouseBirthDate, spouseGender, spouseSmokerStatus, }, mandatoryBenefits, optionalBenefits, }, }) {
    return {
        simulationDate,
        frequency: {},
        participant: {
            id: participantGuid,
            age,
            birthDate,
            categoryId: category.id,
            certificateNumber,
            coveredPerson: {
                Code: '',
                Value: '',
            },
            gender: gender.genderType,
            indianStatus: firstNationStatus,
            participantGuid,
            provinceId: residentialProvince.id,
            recipientId: recipientId === NULL_GUID ? null : recipientId,
            salary: annualSalary,
            smokerStatus: smokerStatus.smokerState,
            spouseAge,
            spouseBirthDate,
            spouseGender,
            spouseSmokerStatus,
            statusId,
            workProvinceId: provinceOfEmployment.id,
        },
        mandatoryBenefits,
        optionalBenefits,
    };
}
function getFrequency(groupRecipientData) {
    return groupRecipientData?.Calendar?.Frequency || {
        Code: -1,
    };
}
export { getDefaultData, getInitialData, getParticipantFormDefaultData, getFrequency, };
