import { forEach } from 'lodash';
import { isFunction } from './types';
const inDeveloppement = (process.env.NODE_ENV === 'development');
export const LoggerClass = function (scope) {
    const logger = {};
    if (inDeveloppement && scope && scope.isDebug) {
        forEach(Object.keys(console), (key) => {
            if (isFunction(console[key])) {
                logger[key] = console[key].bind(window.console);
            }
        });
    }
    else {
        forEach(Object.keys(console), (key) => {
            if (isFunction(console[key])) {
                logger[key] = () => { };
            }
        });
    }
    return logger;
};
const LOGGER = LoggerClass({ isDebug: true });
export default LOGGER;
