import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
export function formatPlanMemberStatus(planMemberStatus) {
    return {
        ...planMemberStatus,
        effectiveDate: formatDate(planMemberStatus.effectiveDate),
        estimatedDateOfReturn: formatDate(planMemberStatus.estimatedDateOfReturn),
        history: planMemberStatus.history.map((history) => ({
            ...history,
            effectiveDate: formatDate(history.effectiveDate),
            estimatedDateOfReturn: formatDate(history.estimatedDateOfReturn),
        })),
    };
}
