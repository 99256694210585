import { isNull as _isNull, isUndefined as _isUndefined } from 'lodash';
export function isNaNGlobal(string) {
    // eslint-disable-next-line no-restricted-globals
    return isNaN(string);
}
export function isFiniteGlobal(string) {
    // eslint-disable-next-line no-restricted-globals
    return isFinite(string);
}
export function isNumber(string) {
    return !isNaNGlobal(parseFloat(string)) && isFiniteGlobal(string) && string[0] !== '0';
}
export function isNull(value) {
    return _isNull(value);
}
export function isUndefined(value) {
    return _isUndefined(value);
}
export function isArray(object) {
    return Array.isArray(object);
}
export function isObject(object) {
    return Boolean(typeof (object) === 'object' && object !== null && object.length === undefined);
}
export function isNotUndefined(value) {
    return Boolean(!isUndefined(value));
}
export function isNotNullOrUndefined(value) {
    return Boolean(!isNull(value) && !isUndefined(value));
}
export function isNullOrUndefined(value) {
    return Boolean(isNull(value) || isUndefined(value));
}
export function isNullOrUndefinedOrEmptyString(value) {
    return isNullOrUndefined(value) || value === '';
}
export function isJson(value) {
    let newValue = (typeof value !== 'string') ? JSON.stringify(value) : value;
    try {
        newValue = JSON.parse(newValue);
    }
    catch (e) {
        return false;
    }
    return (typeof newValue === 'object' && newValue !== null);
}
export function isValidAndNotEmptyJson(json) {
    try {
        // Check if parsed value is an object and not null
        if (json && typeof json === 'object' && !Array.isArray(json)) {
            // Check if the object has at least one property
            return Object.keys(json).length > 0;
        }
    }
    catch (e) {
        // If JSON.parse throws an error, jsonString is not valid JSON
        return false;
    }
    return false;
}
export function isFunction(fct) {
    return typeof fct === 'function';
}
