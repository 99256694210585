import MSAL_CONFIG from '@/msal.config.json';
import AuthService from '@/plugins/msal';
import URL_CONSTANTS from '@/urls.config.json';
import axios from 'axios';
import Q from 'q';
import HTTP_CODES from 'segic_shared_front_end_utils/src/constants/http-codes';
import MESSAGE_TYPE from 'segic_shared_front_end_utils/src/constants/message-types';
import SETTINGS from 'segic_shared_front_end_utils/src/constants/settings';
import METHODS from 'segic_shared_front_end_utils/src/enums/methods';
import LOGGER from 'segic_shared_front_end_utils/src/utils/debugging';
import { getI18nDictionnary } from 'segic_shared_front_end_utils/src/utils/i18n';
import { isNotNullOrUndefined } from 'segic_shared_front_end_utils/src/utils/types';
const FR = {
    success: '',
    info: '',
    error: 'Une erreur est survenue.',
};
const EN = {
    success: '',
    info: '',
    error: 'An error has occurred.',
};
const messages = {
    fr: FR,
    en: EN,
};
const instance = axios.create();
export const getAxios = () => instance;
function getAxiosPromise(serviceData, config, axiosConfig = {}) {
    const finalConfig = {
        method: serviceData.method,
        url: serviceData.apiUrl,
        headers: serviceData.headers,
        data: serviceData.data,
        cancelToken: config.cancelToken,
        responseType: axiosConfig.responseType ? axiosConfig.responseType : 'json',
    };
    if (serviceData.data instanceof FormData) {
        finalConfig.transformRequest = [
            (data) => data,
        ];
    }
    return getAxios()(finalConfig);
}
function getUrl(service, apiPath) {
    let baseUrl = URL_CONSTANTS[service];
    if (baseUrl[baseUrl.length - 1] === '/') {
        baseUrl = baseUrl.split('').slice(0, baseUrl.length - 1).join('');
    }
    return baseUrl + (apiPath[0] === '/' ? apiPath : `/${apiPath}`);
}
function getBaseStatusText(response) {
    const statusText = (response.statusText) ? response.statusText : 'ÒK';
    return statusText.split('|');
}
function isNewStatus(response) {
    return (getBaseStatusText(response).length > 1);
}
function getCallService(response) {
    let service = 'Unknown';
    if (isNewStatus(response)) {
        [service] = getBaseStatusText(response)[0].split('-');
    }
    return service;
}
function getCallCode(response) {
    let code = response.status;
    if (isNewStatus(response)) {
        [code] = getBaseStatusText(response)[0].split('-');
    }
    else if (response.data) {
        const isOutputData = (response.data.data !== undefined) || (response.data.Data !== undefined);
        if (isOutputData && response.data.Code) {
            code = response.data.Code;
        }
        if (isOutputData && response.data.code) {
            code = response.data.code;
        }
    }
    return code;
}
function getCallData(response) {
    let { data } = response;
    if (response.data && response.data.Data) {
        data = response.data.Data;
    }
    if (response.data && response.data.data) {
        data = response.data.data;
    }
    if (/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(data)) {
        data = {
            Id: response.data,
        };
    }
    return data;
}
function getCallMessage(response) {
    const data = getCallData(response);
    let message = (data.Message) ? data.Message : '';
    let service = getCallService(response);
    let key = '';
    const splittedMessage = message.split('|');
    if (splittedMessage.length > 1) {
        [, message] = splittedMessage;
        service = splittedMessage[0].split('-');
        [, key] = service;
    }
    return {
        message,
        service: service[0],
        key,
    };
}
export async function digestResponse(apiPath, response, data, requestData) {
    const finalData = {
        service: getCallService(response),
        code: getCallCode(response),
        internalCode: getCallCode(response),
        data: getCallData(response),
        message: '',
        messageLocales: '',
        messageServer: '',
        messageType: MESSAGE_TYPE.ERROR,
        headers: response.headers,
    };
    if (finalData.code !== HTTP_CODES.OK && typeof finalData.data === 'string' && finalData.data.length !== 0) {
        finalData.messageServer = finalData.data;
        const splittedMessage = finalData.messageServer.split('|');
        if (splittedMessage.length > 1) {
            [, finalData.messageServer] = splittedMessage;
        }
        finalData.data = null;
    }
    else if (finalData.data) {
        if (finalData.code === HTTP_CODES.BAD_REQUEST && finalData.data instanceof Blob) {
            // If the endpoint expects a blob, we need to first parse the response as JSON to get the error message
            const reader = finalData.data.text();
            const jsonResponse = JSON.parse(await reader);
            finalData.data.Message = jsonResponse.Message;
        }
        if (typeof finalData.data.Message === 'string') {
            const contentRegex = /Content: ({[^}]+})/;
            const match = finalData.data.Message.match(contentRegex);
            let responseData;
            if (match?.length && match.length > 1) {
                const contentString = match[1];
                const contentObject = JSON.parse(contentString);
                responseData = getCallMessage({ data: contentObject });
            }
            else {
                responseData = getCallMessage(response);
            }
            finalData.service = responseData.service;
            finalData.messageServer = responseData.message;
            finalData.internalCode = responseData.key;
        }
        else if (typeof finalData.data.message === 'string') {
            finalData.messageServer = finalData.data.message;
        }
        else if (typeof finalData.data.exceptionMessage === 'string') {
            finalData.error = {};
            finalData.error.message = finalData.data.exceptionMessage;
            finalData.error.trace = response.data.StackTrace;
            finalData.error.type = response.data.ExceptionType;
            finalData.error.inner = response.data.innerException;
            finalData.messageServer = finalData.error.message;
        }
        else if (response.data.ExceptionMessage) {
            const exceptionMessage = JSON.parse(response.data.ExceptionMessage);
            finalData.error = {};
            finalData.error.code = exceptionMessage.InternalCode;
            finalData.error.message = (Number(exceptionMessage.InternalCode) !== 9999) ? exceptionMessage.Message : response.data.Message;
            finalData.error.trace = response.data.StackTrace;
            finalData.error.type = response.data.ExceptionType;
            finalData.error.inner = response.data.innerException;
            finalData.internalCode = finalData.error.code;
            finalData.messageServer = finalData.error.message;
        }
    }
    switch (finalData.code) {
        case HTTP_CODES.OK:
        case HTTP_CODES.CREATED:
        case HTTP_CODES.ACCEPTED:
        case HTTP_CODES.NO_CONTENT:
        case HTTP_CODES.MODIFIED:
        case HTTP_CODES.NO_CHANGE:
            finalData.messageType = MESSAGE_TYPE.SUCCESS;
            break;
        case HTTP_CODES.INFO:
            finalData.messageType = MESSAGE_TYPE.INFO;
            finalData.data = `<div><b>${response.data.Data}</b><br/>${response.data.Message}</div>`;
            break;
        case HTTP_CODES.BAD_GATEWAY:
            if (finalData?.data?.ErrorCode) {
                finalData.code = finalData.data.ErrorCode;
                finalData.internalCode = finalData.data.ErrorCode;
            }
            break;
        default:
            finalData.messageType = MESSAGE_TYPE.ERROR;
    }
    finalData.message = getI18nDictionnary(messages, SETTINGS.LANGUAGE).t(finalData.messageType, SETTINGS.LANGUAGE, data);
    let foundMessageKey = false;
    if (requestData.messages) {
        if (requestData.messages[SETTINGS.LANGUAGE] && requestData.messages[SETTINGS.LANGUAGE][requestData.methodName] && isNotNullOrUndefined(requestData.messages[SETTINGS.LANGUAGE][requestData.methodName][finalData.internalCode])) {
            finalData.message = getI18nDictionnary(requestData.messages, SETTINGS.LANGUAGE).t(`${requestData.methodName}.${finalData.internalCode}`, SETTINGS.LANGUAGE, data);
            foundMessageKey = true;
        }
        else {
            LOGGER.warn(`Message not found:: ${SETTINGS.LANGUAGE}: ${requestData.namespace}.${requestData.methodName}.${finalData.internalCode}`);
        }
    }
    if (!foundMessageKey && finalData.messageServer) {
        finalData.message = finalData.messageServer;
    }
    return finalData;
}
function handleQuery(method, apiPath, data, requestData, axiosConfig = {}) {
    const deferred = Q.defer();
    try {
        const source = axios.CancelToken.source();
        this.config.cancelToken = source.token;
        /* ----- msal part begin -----*/
        const url = getUrl(requestData.service, apiPath);
        const tokenRequest = {
            scopes: [],
            forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
        };
        tokenRequest.scopes = MSAL_CONFIG.b2cScopes[requestData.service];
        AuthService.getToken(tokenRequest).then((token) => {
            const bearer = `Bearer ${token}`;
            const headers = {
                Authorization: bearer,
                'Content-Type': 'application/json',
            };
            /* ----- msal part end -----*/
            const serviceData = {
                headers,
                apiUrl: url,
                method: method.toUpperCase(),
            };
            if (typeof data !== 'undefined') {
                serviceData.data = data;
            }
            const myInterceptor = getAxios().interceptors.request.use((config) => {
                config.requestData = requestData;
                return config;
            }, (error) => Promise.reject(error));
            const promise = getAxiosPromise(serviceData, this.config, axiosConfig);
            getAxios().interceptors.request.eject(myInterceptor);
            promise
                .then(async (response) => {
                const digestedData = await digestResponse(url, response, data, requestData);
                const type = digestedData.messageType;
                deferred.promise.done = true;
                if (type === MESSAGE_TYPE.SUCCESS) {
                    deferred.resolve(digestedData);
                }
                else {
                    deferred.reject(digestedData);
                }
            })
                .catch(async (reason) => {
                LOGGER.error(reason);
                deferred.promise.done = true;
                if (axios.isCancel(reason)) {
                    deferred.reject({
                        statusCode: 0,
                        code: 0,
                        data: reason.message,
                    });
                }
                else {
                    const digestedData = await digestResponse(url, reason.response, data, requestData);
                    deferred.reject(digestedData);
                }
            });
        }, (error) => {
            deferred.reject(error);
        });
        deferred.promise.reject = source.cancel;
        deferred.promise.lang = SETTINGS.LANGUAGE;
    }
    catch (e) {
        throw Error(e);
    }
    return deferred.promise;
}
class Proxy {
    config = {
        headers: {},
    };
    get(apiPath, requestData = null, axiosConfig = {}) {
        return handleQuery.call(this, METHODS.GET, apiPath, null, requestData, axiosConfig);
    }
    post(apiPath, data, requestData = null, axiosConfig = {}) {
        return handleQuery.call(this, METHODS.POST, apiPath, data, requestData, axiosConfig);
    }
    put(apiPath, data, requestData = null) {
        return handleQuery.call(this, METHODS.PUT, apiPath, data, requestData);
    }
    delete(apiPath, data = null, requestData = null) {
        return handleQuery.call(this, METHODS.DELETE, apiPath, data, requestData);
    }
}
export default new Proxy();
