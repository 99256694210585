import { formatPerson } from '@/store/plan-member/formatter/person';
import CHILDREN_STATUS from 'segic_shared_front_end_utils/src/enums/children-status';
import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
export function formatStatus(status) {
    return {
        ...status,
        statusDate: formatDate(status.statusDate),
    };
}
export function formatChildren(children) {
    return children.map((child) => ({
        ...child,
        person: formatPerson(child.person),
        schoolAttestationEndDate: formatDate(child.schoolAttestationEndDate),
        schoolAttestationStartDate: formatDate(child.schoolAttestationStartDate),
        statusDate: formatDate(child.statusDate),
        statuses: child.statuses.map((status) => formatStatus(status)),
        sortedType: child.status === CHILDREN_STATUS.CONSTANT.ACTIVE || child.status === CHILDREN_STATUS.CONSTANT.DISABLED ? 1 : 2,
    }));
}
