import { formatDate } from 'segic_shared_front_end_utils/src/utils/date';
export default {
    input(data) {
        return {
            ...data,
            EffectiveDate: formatDate(data.EffectiveDate),
        };
    },
    display(data) {
        return data;
    },
};
