function isImage(extension) {
    return ['jpg', 'jpeg', 'png'].includes(extension);
}
function isExcelXlsx(extension) {
    return ['xlsx'].includes(extension);
}
function isExcelXls(extension) {
    return ['xls'].includes(extension);
}
function isWordDocx(extension) {
    return ['docx'].includes(extension);
}
function isWordDoc(extension) {
    return ['doc'].includes(extension);
}
function isPdf(extension) {
    return ['pdf'].includes(extension);
}
function getApplicationDataType(extension, byteArray) {
    if (isImage(extension)) {
        return `data:image/png;base64,${byteArray}`;
    }
    if (isExcelXlsx(extension)) {
        return `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${byteArray}`;
    }
    if (isExcelXls(extension)) {
        return `data:application/vnd.ms-excel;base64,${byteArray}`;
    }
    if (isWordDocx(extension)) {
        return `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${byteArray}`;
    }
    if (isWordDoc(extension)) {
        return `data:application/msword;base64,${byteArray}`;
    }
    if (isPdf(extension)) {
        return `data:application/pdf;base64,${byteArray}`;
    }
    return null;
}
export function createAnchor(fileName, href) {
    const downloadLink = document.createElement('a');
    downloadLink.download = fileName;
    downloadLink.href = href;
    return downloadLink;
}
export function download(downloadLink, element = document.body) {
    element.appendChild(downloadLink);
    downloadLink.click();
    element.removeChild(downloadLink);
    return downloadLink;
}
export function downloadFile(fileName, extension, byteArray) {
    const href = getApplicationDataType(extension, byteArray);
    // Prevents multiple identical same extensions.
    const regex = new RegExp(`.${extension}+$`, 'i');
    const fileNameWithoutExtensions = fileName.replace(regex, '');
    const finalFileName = `${fileNameWithoutExtensions}.${extension}`;
    const downloadLink = createAnchor(finalFileName, href);
    return download(downloadLink);
}
export function downloadFileFromBlankTab(url, element = document.body) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.target = '_blank';
    return download(downloadLink, element);
}
export function getFileNameFromContentDisposition(payload) {
    const contentDisposition = payload.headers['content-disposition'];
    const filenameUTFMatch = contentDisposition && contentDisposition.match(/filename\*=UTF-8''(.+)$/);
    const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
    if ((!filenameMatch?.length || filenameMatch?.length === 1) && (!filenameUTFMatch?.length || filenameUTFMatch.length === 1)) {
        return '';
    }
    if (filenameUTFMatch?.length) {
        return decodeURIComponent(filenameUTFMatch[1]);
    }
    return filenameMatch[1];
}
export function formatAPIResponseForDownload(data, payload) {
    return {
        fileName: getFileNameFromContentDisposition(payload),
        url: window.URL.createObjectURL(data),
    };
}
