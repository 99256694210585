import StoreBasics from '@/store/basics';
import storeUtils from '@/store/utils';
import entities from './entities';
import Service from './service';
const cacheDelay = -1;
const storeData = [
    {
        key: 'data',
        defaultValue: {},
        methods: [
            {
                key: 'get',
                params: ['clientGuid', 'groupGuid', 'participantGuid', 'data'],
                cacheDelay,
                inputFormatting(state, stateKey, data) {
                    state[stateKey].value = entities.input(data);
                },
            },
        ],
        service: Service,
    },
];
const state = storeUtils.mergeStates(StoreBasics.statesFromData(storeData));
const getters = storeUtils.mergeGetters(StoreBasics.gettersFromData(storeData));
const mutations = storeUtils.mergeMutations(StoreBasics.mutationsFromData(storeData));
const actions = storeUtils.mergeActions(StoreBasics.actionsFromData(storeData));
const PlanMemberStore = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
export default PlanMemberStore;
