export default {
    annualEarnings: 'Salaire annuel',
    autoCreation: 'Création automatique',
    automaticCreationDate: 'Date de création automatique',
    appliesToThisPlanMember: 'S\'applique à ce participant',
    automaticEnrollment: 'Adhésion automatique',
    automaticEnrollmentDate: 'Date d\'adhésion automatique',
    birthDate: 'Date de naissance',
    cellphone: 'Cellulaire',
    certificate: 'Certificat',
    communicationLanguage: 'Langue de communication',
    completedFileToImport: 'Fichier complété à importer *',
    coverageEffectiveDate: 'Date effective d\'assurance',
    creationDate: 'Adhésion automatique',
    downloadTemplate: 'Télécharger le gabarit',
    email: 'Courriel',
    emailDeliveryMayTakeAMoment: 'L\'envoi des courriels peut prendre un moment.',
    emailReminderDate: 'Date courriel de rappel',
    employmentProvince: 'Province d\'emploi',
    estimatedDateOfReturnToWork: 'Date estimée de retour au travail',
    failed: 'Rejeté',
    fixTheErrorsBelowInTheSourceFileAndValidateTheFileAgain: 'Corrigez les erreurs dans le fichier source et soumettez-le à nouveau',
    followUp: 'Suivi',
    from: 'Du',
    gender: 'Genre',
    grouping: 'Regroupement',
    groupsFileTemplate: 'Gabarit pour ce régime',
    hiringDate: 'Date d\'embauche',
    hiringDateCoverageEffectiveDate: 'Date d\'embauche<br/>Date effective d\'assurance',
    ifAnEmailCantBeDeliveredTheMembersOnboardingStatusWillBeSetToBounced: 'Si un courriel ne peut pas être délivré, le statut d\'autoadhésion du participant sera défini à Rejeté.',
    importPlanMembers: 'Importer des participants',
    invitationEmailsWillBeSentImmediatelyRegardlessOfCoverageEffectiveDates: 'Les courriels seront envoyés immédiatement, peu importe la date effective de couverture.',
    inviteMembers: 'Inviter les participants',
    inviteNewPlanMembers: 'Inviter de nouveaux participants',
    inviteNewPlanMembersToOnboarding: 'Inviter de nouveaux participants à l\'autoadhésion',
    invitePlanMember: 'Inviter un participant',
    invitePlanMembers: 'Inviter des participants',
    language: 'Langue',
    lastNameFirstNameEmail: 'Nom, Prénom,<br/>Courriel',
    line: 'Ligne',
    makeSureYouHaveSelectedTheCorrectFileThatItOpensWithoutErrorsInExcelAndThatItFollowsTheTemplateFormatAsSpecified: 'Vérifier que vous avez sélectionné le bon fichier, qu\'il s\'ouvre sans erreur dans Excel, et qu\'il respecte le format du gabarit tel que spécifié.',
    modificationToTheProtectionsOnSeveralBenefitsIsOnlyPossibleOnTheActiveBenefitsToModifyTheTerminatedBenefitMakeTheModificationOnTheBenefitItself: 'La modification des protections sur plusieurs garanties est possible sur les garanties actives seulement. Pour modifier la garantie terminée, faire la modification sur la garantie elle-même',
    mustBeInExcelFormatXlsx: 'Doit être au format Excel (.xlsx)',
    nErrors: 'erreur | erreurs',
    nInvitation: 'invitation | invitations',
    noInvitationsWereSent: 'Aucune invitation n\'a été envoyée.',
    notes: 'Notes',
    occupationAnnualSalary: 'Occupation<br/>Salaire annuel',
    onboarding: 'Autoadhésion',
    payrollDeductionFile: 'Déduction de la paie',
    reminderEmail: 'Courriel de rappel',
    scheduled: 'Planifié',
    proofSchoolAttendanceRequired: 'Attestation scolaire requise',
    selectTheExcelFileContainingNewMembersToImport: 'Sélectionner le fichier Excel contenant les nouveaux participants à importer',
    sendInvitations: 'Envoyer les invitations ?',
    sendTheInvitation: ' Envoyer l\'invitation',
    sent: 'Envoyé',
    sentOn: 'Envoyé le',
    status: 'Statut',
    theFileContainsFormatErrors: 'Le fichier contient des erreurs de format',
    theFileIsUnreadableOrCorruptedOrIsNotInTheExpectedExcelFormat: 'Le fichier est illisible ou corrompu, ou n\'est pas dans le format Excel attendu',
    theFileIsValid: 'Le fichier est valide.',
    theInitialInvitationEmailIsBeingSent: 'Le courriel d\'invitation initiale est en cours d\'envoi.',
    to: 'Au',
    validateFile: 'Valider le fichier',
    willBeSent: 'sera envoyée | seront envoyées',
};
