import { EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES } from '@/mixins/security/codes/employer-portal';
import { getEmptyComponents } from '@/utils/page-layouts';
import SECURITY_PERMISSIONS from '@/enums/security-permissions';
import Header from '../header.vue';
import ONBOARDING_ROUTE_NAME from '../route-name';
const routes = [];
const children = [];
const components = getEmptyComponents();
components.default = () => import(/* webpackChunkName: "participants" */ './index.vue');
routes.push({
    name: ONBOARDING_ROUTE_NAME.INVITE,
    path: 'invite',
    components,
    children,
    meta: {
        headerComponents: {
            extension: {
                component: Header,
                height: 114,
            },
        },
        title: 'onboarding',
        resourcePermission: SECURITY_PERMISSIONS.VIEW_EDIT,
        resource: EMPLOYER_PORTAL_SECURITY_RESOURCES_NAMES.ONBOARDING_RESOURCE,
    },
});
export default routes;
