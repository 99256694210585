import VueRouter from 'vue-router';
import _ from 'lodash';
import Q from 'q';
import { replaceStringLocaleData } from 'segic_shared_front_end_utils/src/utils/routing';
import { getClientGroupGuid } from '@/utils/routing';
import routes from '@/page-layouts/router';
import AuthService from '@/plugins/msal';
import StoreExtender from '@/store/extender';
import URL_CONSTANTS from '@/urls.config.json';
import { checkAccess } from '@/mixins/security';
import { getFirstRouteAccessible } from '@/utils/route';
import administratorRoutes from '@/page-layouts/administrator/route-names';
import STORE_CONSTANT_NAMES from '@/store/names';
import GlobalUserStore from '@/store/global/user';
const router = new VueRouter({
    mode: 'history',
    routes,
    linkExactActiveClass: 'active',
});
let globalUserStore = null;
function beforeEachHandler(to, from) {
    const deferred = Q.defer();
    AuthService.getLoginState().then(() => {
        globalUserStore = new StoreExtender(null, STORE_CONSTANT_NAMES.GLOBALS.USER, GlobalUserStore);
        function getUser() {
            const defered = Q.defer();
            if (globalUserStore.getGetter('get')) {
                defered.resolve(globalUserStore.getGetter('get'));
            }
            else {
                globalUserStore.sendActions('get').then(() => {
                    defered.resolve(globalUserStore.getGetter('get'));
                });
            }
            return defered.promise;
        }
        const user = getUser();
        user.then((userData) => {
            if (!userData) {
                AuthService.logout();
            }
            getClientGroupGuid(to.params, globalUserStore);
            const clonedTo = _.merge({}, to);
            clonedTo.path = (clonedTo.path.endsWith('/')) ? clonedTo.path : `${clonedTo.path}/`;
            clonedTo.path = clonedTo.path.replace(/%7B/g, '{').replace(/%7D/g, '}');
            getClientGroupGuid(from.params, globalUserStore);
            const clonedFrom = _.merge({}, from);
            clonedFrom.path = (clonedFrom.path.endsWith('/')) ? clonedFrom.path : `${clonedFrom.path}/`;
            clonedFrom.path = clonedFrom.path.replace(/%7B/g, '{').replace(/%7D/g, '}');
            router.isFirstRoute = (from.name === null);
            const title = '';
            let nextUrl = null;
            const isEmployerPortal = _.find(userData.securityGroups, 'isEmployerPortalGroup');
            const isParticipantPortal = _.find(userData.securityGroups, 'isPortalUsersGroup');
            if (router.isFirstRoute && clonedTo.name === 'dashboard') {
                if (isEmployerPortal && !isParticipantPortal) {
                    const name = 'groups';
                    let params = {};
                    let firstRouteAccessible = null;
                    if (userData.accessibleGroups.length === 1) {
                        params = {
                            clientCode: userData.accessibleGroups[0].ClientCode,
                            groupCode: userData.accessibleGroups[0].Code,
                        };
                        firstRouteAccessible = getFirstRouteAccessible(administratorRoutes.INDEX, userData.securityPermissionSets, router.options.routes);
                    }
                    nextUrl = {
                        name: firstRouteAccessible ? firstRouteAccessible.name : name,
                        params,
                    };
                }
                else if (!isEmployerPortal && isParticipantPortal) {
                    AuthService.clearData();
                    window.location.href = URL_CONSTANTS.portalParticipant;
                    return;
                }
            }
            const { resourcePermission } = clonedTo.meta;
            const { resource } = clonedTo.meta;
            if (!nextUrl && resourcePermission && resource && !checkAccess(resourcePermission, resource, userData.securityPermissionSets)) {
                nextUrl = {
                    name: 'accessDenied',
                };
            }
            deferred.resolve({
                title, clonedTo, clonedFrom, nextUrl,
            });
        });
    }).catch(() => {
        console.log('loggin out');
    });
    return deferred.promise;
}
router.beforeEach((to, from, next) => {
    beforeEachHandler(to, from)
        .then((response) => {
        /* set page title */
        response.title += router.app.$t(to.meta.title);
        response.title = replaceStringLocaleData.call(router.app, response.title, router.app.$t);
        document.title = response.title;
        if (response.nextUrl) {
            next(response.nextUrl);
        }
        else {
            next();
        }
        window.focus();
    })
        .catch((err) => {
        console.log('catch', err);
        next(false);
    });
});
export default router;
