import { forEach, get } from 'lodash';
import { removeSpecialCharacters } from './string';
import { isNaNGlobal, isNullOrUndefined, isObject } from './types';
export const DATA_TABLE_KEYS_TO_SEARCH = 'keysToSearch';
export function getLoadingText() {
    return this.$t('loadingItems');
}
export function getNoDataText() {
    return this.noDataText ? this.noDataText : this.$t('noDataAvailable');
}
export function getNoResultsText() {
    return this.noResultText ? this.noResultText : this.$t('noMatchingRecordsFound');
}
export function updateSearch(search) {
    this.search = search;
}
export function sortWithQueryKey(items, sortBy, sortDesc, locale, customSorters) {
    let sortByFormatted = [];
    sortBy.forEach((sortByItem) => {
        const hasQueryKey = items.filter((item) => item[sortByItem]?.queryKey?.[locale]).length === items.length;
        const sortKey = hasQueryKey ? `${sortByItem}.queryKey.${locale}` : sortByItem;
        sortByFormatted.push(sortKey);
    });
    return sortItems(items, sortByFormatted, sortDesc, locale, customSorters);
}
export function customSort(items, sortBy, sortDesc, locale, customSorters) {
    const sortByFormatted = [];
    forEach(sortBy, (item) => {
        const key = `${item}.text`;
        if (Array.isArray(this.sortWanted) && this.sortWanted.indexOf(key) !== -1) {
            const isI18n = get(items[0], `${key}.${locale}`) !== undefined;
            sortByFormatted.push(isI18n ? `${key}.${locale}` : key);
        }
        else {
            sortByFormatted.push(item);
        }
    });
    return sortItems(items, sortByFormatted, sortDesc, locale, customSorters);
}
export function isValueInSearch(value, search) {
    const valueWithoutSpecialCharacters = removeSpecialCharacters(value).toLowerCase();
    const searchWithoutSpecialCharacters = removeSpecialCharacters(search).toLowerCase();
    return valueWithoutSpecialCharacters.indexOf(searchWithoutSpecialCharacters) !== -1;
}
export function isComponent(item) {
    return Boolean(isObject(item) && item.component);
}
export function customFilter(value, search, locale) {
    if (isNullOrUndefined(value)) {
        return false;
    }
    let realValue = value.toString().replace(/(<([^>]+)>)/gi, ''); // strip html tag
    if (isComponent(value)) {
        if (value.text?.[locale]) {
            realValue = value.text?.[locale];
        }
        else {
            realValue = value.text ? value.text : '';
        }
    }
    return isValueInSearch(realValue, search);
}
// todo: remove queryKey and text property and use keysToSearch array instead
// ex value :{
//   object: {
//    color: 'red',
//       status: {
//       text: 'rouge',
//        description:'super beau'
//     }
//   valueToSearch: ['object.color.text', 'object.color.description'],
// }
export function searchWithQueryKey(value, search, item, locale) {
    if (isNullOrUndefined(value)) {
        return false;
    }
    if (value[DATA_TABLE_KEYS_TO_SEARCH]) {
        const valueToSearch = createStringFromObjectBaseOnKey(value);
        return isValueInSearch(valueToSearch, search);
    }
    if (value.queryKey) {
        const queryKey = value.queryKey?.[locale] ? value.queryKey[locale] : value.queryKey;
        return isValueInSearch(queryKey, search);
    }
    return customFilter(value, search, locale);
}
export function createStringFromObjectBaseOnKey(object, key = DATA_TABLE_KEYS_TO_SEARCH) {
    if (!object?.[key]) {
        return object;
    }
    return object[key].map((keyValue) => get(object, keyValue)).join(' ');
}
export function sortItems(items, sortBy, sortDesc, locale, customSorters) {
    if (sortBy === null || !sortBy.length)
        return items;
    const stringCollator = new Intl.Collator(locale, { sensitivity: 'accent', usage: 'sort' });
    return items.sort((a, b) => {
        for (let i = 0; i < sortBy.length; i += 1) {
            const sortKey = sortBy[i];
            let sortA = createStringFromObjectBaseOnKey(get(a, sortKey));
            let sortB = createStringFromObjectBaseOnKey(get(b, sortKey));
            if (sortDesc[i]) {
                [sortA, sortB] = [sortB, sortA];
            }
            if (customSorters && customSorters[sortKey]) {
                const customResult = customSorters[sortKey](sortA, sortB);
                // eslint-disable-next-line no-continue
                if (!customResult)
                    continue;
                return customResult;
            }
            // Check if both cannot be evaluated
            if (sortA === null && sortB === null) {
                // eslint-disable-next-line no-continue
                continue;
            }
            [sortA, sortB] = [sortA, sortB].map((s) => (s || '').toString().toLocaleLowerCase());
            if (sortA !== sortB) {
                if (!isNaNGlobal(sortA) && !isNaNGlobal(sortB))
                    return Number(sortA) - Number(sortB);
                return stringCollator.compare(sortA, sortB);
            }
        }
        return 0;
    });
}
