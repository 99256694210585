import I18n from '@/plugins/vue-i18n';
import { Masker, MASKS, TOKENS } from '@/utils/mask/';
import { getProvinceCode } from '@/utils/provinces';
import { forEach, isEqual, isUndefined } from 'lodash';
import CURRENCIES from 'segic_shared_front_end_utils/src/constants/currencies';
import { NULL_GUID } from 'segic_shared_front_end_utils/src/constants/null';
import { isDateAfterNow, isDateSameOrAfterAnotherDate, today } from 'segic_shared_front_end_utils/src/utils/date';
import { isNaNGlobal } from 'segic_shared_front_end_utils/src/utils/types';
export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
export function displayPostalCode(postalCode) {
    return Masker(postalCode, MASKS.POSTAL_CODE, true, TOKENS);
}
export function displayAddress(data) {
    const provinceCode = getProvinceCode(data.provinceId);
    return `<div>${data.civicAddress}<br/>
            ${data.city} <span class="ml-1 mr-2">${provinceCode}</span> <span class="text-no-wrap">${data.postalCode}</span></div>`;
}
export function maskedPhoneNumber(phone) {
    let phoneFomated = phone.replace('-', '');
    phoneFomated = Masker(phoneFomated, MASKS.PHONE, true, TOKENS);
    return phoneFomated;
}
export function maskNas(nas) {
    return Masker(nas, MASKS.NAS, true, TOKENS);
}
export function unMaskNas(nas) {
    return Masker(nas, MASKS.NAS, false, TOKENS);
}
export function unMaskedPhone(phone) {
    return Masker(phone, MASKS.PHONE, false, TOKENS);
}
export function currencyWithoutSeparator(currency, returnNumber) {
    if (!currency) {
        return currency;
    }
    const replace = `[${CURRENCIES.SEPARATOR.EN},${CURRENCIES.SEPARATOR.FR},'$', ' ']`;
    const regex = new RegExp(replace, 'g');
    const currencyStr = currency.toString();
    let currencyFormated = currencyStr.replace(regex, '');
    if (returnNumber) {
        currencyFormated = Number(currencyFormated);
    }
    return currencyFormated;
}
export function formatCurrency(num, withDollarSign, digitLengthParam, optionalDigit) {
    if ((typeof num === 'undefined' || num === null) && isNaNGlobal(num)) {
        return num;
    }
    let digitLength = typeof digitLengthParam !== 'undefined' ? digitLengthParam : 2;
    const divider = 10 ** digitLength;
    let numWithoutSeparator = currencyWithoutSeparator(num);
    if (optionalDigit) {
        numWithoutSeparator = numWithoutSeparator.toString().replace('.00', '');
        digitLength = numWithoutSeparator.indexOf('.') !== -1 ? digitLength : 0;
    }
    let value = (Math.round(numWithoutSeparator * divider) / divider).toFixed(digitLength);
    if (withDollarSign) {
        value = I18n.t('common.currency', { currency: value });
    }
    const currencySeparator = CURRENCIES.SEPARATOR[I18n.locale.toLocaleUpperCase()];
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, currencySeparator);
    return parts.join('.');
}
export function displayYesNo(isYes) {
    return isYes ? I18n.t('common.yes') : I18n.t('no');
}
export function getQueryParamsObject() {
    let queryArray = [];
    if (document.location.search !== '' && document.location.search !== '?') {
        queryArray = document.location.search.substr(1).split('&');
    }
    const query = {};
    forEach(queryArray, (value) => {
        const splitValue = value.split('=');
        if (splitValue[1] !== '') {
            [, query[splitValue[0]]] = splitValue;
        }
    });
    return query;
}
export function setQueryParamsObject(name, value) {
    const query = getQueryParamsObject();
    query[name] = value;
    let queryString = '?';
    let index = 0;
    // eslint-disable-next-line no-shadow
    forEach(query, (value, key) => {
        if (value !== '') {
            if (index !== 0) {
                queryString += '&';
            }
            queryString += `${key}=${value}`;
            index += 1;
        }
    });
    window.history.replaceState(null, null, queryString);
}
export function getNestedObject(item, key) {
    const splittedKey = key.split('.');
    // eslint-disable-next-line no-shadow
    return splittedKey.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), item);
}
export function isArrayEqual(x, y) {
    return (x).xorWith(y, isEqual).isEmpty();
}
export function isEmptyGuid(value) {
    if (value === NULL_GUID) {
        return null;
    }
    return value;
}
export function isNull(value) {
    return value === null;
}
export function isNotNullOrUndefined(value) {
    return Boolean(!isNull(value) && !isUndefined(value));
}
export function isNullOrUndefined(value) {
    return Boolean(isNull(value) || isUndefined(value));
}
export function statusEffectiveDateQuery() {
    const query = getQueryParamsObject();
    return query?.statusEffectiveDate && isDateAfterNow(query?.statusEffectiveDate) ? query.statusEffectiveDate : null;
}
// eslint-disable-next-line no-shadow
export function isDateSameOrAfter(date, debug) {
    const statusEffectiveDate = statusEffectiveDateQuery() ? statusEffectiveDateQuery() : today();
    if (debug) {
        console.log(`StatusEffectiveDate: ${statusEffectiveDate}`);
        console.log(`Date to check: ${date}`);
        console.log(`isSameOrAfter: ${isDateSameOrAfterAnotherDate(statusEffectiveDate, date)}`);
    }
    return isDateSameOrAfterAnotherDate(statusEffectiveDate, date);
}
export default {
    displayAddress,
    displayPostalCode,
    isDateSameOrAfter,
    maskedPhoneNumber,
    displayYesNo,
    currencyWithoutSeparator,
    formatCurrency,
    getNestedObject,
    getQueryParamsObject,
    guid,
    isArrayEqual,
    setQueryParamsObject,
    unMaskedPhone,
};
